// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function App() {

  const navigate = useNavigate()
  const gotoLogin = ()=>{
    navigate('/login')
  }

  const gotoSignUP = ()=>{
    navigate('/signup')
  }
  return (
    <div className="flex justify-center items-center min-h-screen bg-[#128764] px-4 font-sans">
      
      {/* Content Wrapper */}
      <div className="flex flex-col md:flex-row justify-between items-center max-w-6xl w-full space-y-6 md:space-y-0">
        
        {/* Left Section */}
        <div className="md:w-6/12 flex-1 space-y-6 text-center md:text-left">
          <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">#1 AI Immigration Consultant</h1>
          <p className="text-base md:text-lg text-gray-700">No more wrong paperwork. Our Immigration AI will do all the work for you, flawlessly and faster.</p>
          
          <div className="space-x-0 md:space-x-4 space-y-4 md:space-y-0 flex flex-col md:flex-row justify-center md:justify-start">
            <button className="bg-yellow-400 hover:bg-yellow-500 text-black font-medium py-3 px-6 rounded-md" onClick={gotoLogin}>
              
              Sign In
              
            
            </button>
            <button className="border border-black text-black py-3 px-6 rounded-md" onClick={gotoSignUP}>
              <>
              Join for free
              </>
            </button>
          </div>
        </div>

        {/* Right Section */}
        <div className="relative md:w-6/12 w-full flex justify-center mt-4">
          {/* Consultant Image Container */}
          <div className="w-full max-w-md md:max-w-none md:w-[90%] flex justify-center">
            {/* Consultant Image */}
            <img
              src="/consultant.jpeg" // Replace with actual image URL
              alt="Consultant"
              className="rounded-lg shadow-lg w-full h-[400px] md:h-[500px] object-cover"
            />
          </div>

          {/* Floating Stats */}
          <div className="absolute top-[-20px] left-[10px] md:top-0 md:left-[-60px] bg-white shadow-lg p-3 rounded-lg">
            <p className="text-xl font-bold">10k</p>
            <span className="text-gray-500">Approved Cases</span>
          </div>

          <div className="absolute top-[110px] right-[10px] md:top-20 md:right-[-50px] bg-white shadow-lg p-3 rounded-lg">
            <p className="text-xl font-bold">11k</p>
            <span className="text-gray-500">Applications</span>
          </div>

          <div className="absolute bottom-[-40px] left-[10px] md:bottom-[-30px] md:left-[-50px] bg-white shadow-lg p-3 rounded-lg">
            <p className="text-xl font-bold">9K</p>
            <span className="text-gray-500">GreenCards</span>
            <div className="flex mt-2 space-x-2">
              {/* Replace these with real avatar images */}
              <img src="https://via.placeholder.com/30" alt="avatar1" className="rounded-full" />
              <img src="https://via.placeholder.com/30" alt="avatar2" className="rounded-full" />
              <img src="https://via.placeholder.com/30" alt="avatar3" className="rounded-full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
