import React, { useState , useEffect} from "react";
import { motion } from "framer-motion";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { FaPaperPlane } from 'react-icons/fa';
import { FaExclamationCircle } from 'react-icons/fa';
import { FaCheckCircle, FaUser} from 'react-icons/fa';
import Intercom from '@intercom/messenger-js-sdk';
import axios from 'axios';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import PaymentForm from "./PaymentForm";

import Spinner from "./spinner";


Intercom({
  app_id: 'qd9u2fas',
});

const PaymentModal = ({ isOpen, onClose, userId , location}) => {
  const [selectedPlan, setSelectedPlan] = useState(null); 
  const [planName, setPlanName] = useState(null)
 

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-8 rounded-lg w-full max-[80%] max-h-[90vh] overflow-y-auto">
        {selectedPlan === null ? ( // Conditional rendering based on selectedPlan
          <>
            <h2 className="text-2xl font-bold mb-6 text-center pt-4">Select a Payment Plan</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {/* Essentials Plan */}
              

              {/* Enhanced Plan */}
              <div className="p-4 border border-gray-300 rounded-lg shadow-lg">
                <h3 className="font-bold text-lg mb-2">Starter</h3>
                <ul className="list-disc pl-5 mb-4">
                  <li>Fill all your documents</li>
                  <li>Send application to you at home for review</li>
                  <li>Package and label all documents for USCIS</li>
                  <li>Unlimited Live Chat Support</li>
                  <li>Translation of required documents</li>
                  <li>Ship document for you</li>
                  <li>Access to MigrantGenie Chat AI ( AI with over 20 years of immigration expererience ) </li>
                </ul>
                <p className="font-bold text-xl mb-4">$500</p>
                <button 
                  className="bg-green-500 text-white py-2 px-4 rounded-lg mt-2 w-full" 
                  onClick={() => {
                    setSelectedPlan(50000);  // First state update
                    setPlanName('Starter');  // Second state update
                }}
                >
                  Start Application
                </button>
              </div>

              {/* Professional Plan */}
              <div className="p-4 border border-gray-300 rounded-lg shadow-lg">
                <h3 className="font-bold text-lg mb-2">Assisted</h3>
                <ul className="list-disc pl-5 mb-4">
                  <li>Everything in Starter Plan, plus : </li>
                  <li>Assigned Case Manager</li>
                  <li>Translation of 5 additional pages to include in your application ( if needed )</li>
                  <li>One consultation with an immigration attorney to answer questions</li>
                  <li>One on One consultation call to go through your provided information</li>
                  
                </ul>
                <p className="font-bold text-xl mb-4">$700*</p>
                <button 
                  className="bg-green-500 text-white py-2 px-4 rounded-lg mt-2 w-full" 
                  onClick={() => {
                    setSelectedPlan(70000);  // First state update
                    setPlanName('Essentails');  // Second state update
                }}
                >
                  Start Application
                </button>
              </div>

              <div className="p-4 border border-gray-300 rounded-lg shadow-lg">
                <h3 className="font-bold text-lg mb-2">Hands-Free</h3>
                <ul className="list-disc pl-5 mb-4">
                  <li>Everything in Assisted Plan, plus:</li>
                  <li>One on One Interview Preparation for your USCIS interview</li>
                  <li>48 Hours Express Shipping</li>
                  <li>One consultation with an immigration attorney to prepare you for your interview</li>
                </ul>
                <p className="font-bold text-xl mb-4">$800</p>
                <button 
                  className="bg-green-500 text-white py-2 px-4 rounded-lg mt-2 w-full" 
                  onClick={() => {
                    setSelectedPlan(80000);  // First state update
                    setPlanName('Hands Free');
                  // Second state update
                }}
                
                >
                  Start Application
                </button>
              </div>
            </div>
          </>
        ) : (
          // Render the PaymentForm if a plan is selected
          <PaymentForm amount={selectedPlan} onClose={onClose} plan={planName} userId={userId} location={location} />
        )}

        <div className="flex justify-center mt-6">
          <button onClick={onClose} className="text-red-500 hover:underline">
            Close
          </button>
        </div>

        <p className="text-center text-xs mt-2 ">*Packages & Pricing do not include required government fees.
           Government fees are paid directly to USCIS upon filing</p>
        <p className="text-xs text-center mt-8">MigrantGenie , Inc is not a law firm.
           The services and information provided are not legal advice
            and do not substitute the same level of advice, opinion, guidance or recommendation
             that a licensed immigration attorney can provide. MigrantGenie is not affiliated with the USCIS. 
             The purchase price of services does not include USCIS government filing fees.</p>
      </div>
    </div>
  );
};

const PaymentModalForRenewal = ({ isOpen, onClose, userId , location}) => {
  const [selectedPlan, setSelectedPlan] = useState(null); 
  const [planName, setPlanName] = useState(null)
 

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
      <div className=" dark:bg-gray-800 p-8 rounded-lg w-[90%]  max-h-[90vh] overflow-y-auto">
        {selectedPlan === null ? ( // Conditional rendering based on selectedPlan
          <>
            <h2 className="text-2xl font-bold mb-6 text-center pt-4">Select a Payment Plan</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {/* Essentials Plan */}
              

              {/* Enhanced Plan */}
              <div className="p-4 border border-gray-300 rounded-lg shadow-lg">
                <h3 className="font-bold text-lg mb-2">Starter</h3>
                <ul className="list-disc pl-5 mb-4">
                  <li>Fill all your documents</li>
                  <li>Send application to you at home for review</li>
                  <li>Package and label all documents for USCIS</li>
                  <li>Unlimited Live Chat Support</li>
                  <li>Translation of required documents</li>
                  <li>Ship document for you</li>
                  <li>Access to MigrantGenie Chat AI ( AI with over 20 years of immigration expererience ) </li>
                </ul>
                <p className="font-bold text-xl mb-4">$300</p>
                <button 
                  className="bg-green-500 text-white py-2 px-4 rounded-lg mt-2 w-full" 
                  onClick={() => {
                    setSelectedPlan(30000);  // First state update
                    setPlanName('Starter');  // Second state update
                }}
                >
                  Start Application
                </button>
              </div>

              {/* Professional Plan */}
              <div className="p-4 border border-gray-300 rounded-lg shadow-lg">
                <h3 className="font-bold text-lg mb-2">Assisted</h3>
                <ul className="list-disc pl-5 mb-4">
                  <li>Everything in Starter Plan, plus : </li>
                  <li>Assigned Case Manager</li>
                  <li>Translation of 5 additional pages to include in your application ( if needed )</li>
                  <li>One consultation with an immigration attorney to answer questions</li>
                  <li>One on One consultation call to go through your provided information</li>
                  
                </ul>
                <p className="font-bold text-xl mb-4">$400*</p>
                <button 
                  className="bg-green-500 text-white py-2 px-4 rounded-lg mt-2 w-full" 
                  onClick={() => {
                    setSelectedPlan(40000);  // First state update
                    setPlanName('Assisted');  // Second state update
                }}
                >
                  Start Application
                </button>
              </div>

              <div className="p-4 border border-gray-300 rounded-lg shadow-lg">
                <h3 className="font-bold text-lg mb-2">Hands-Free</h3>
                <ul className="list-disc pl-5 mb-4">
                  <li>Everything in Assisted Plan, plus:</li>
                  <li>One on One Interview Preparation for your USCIS interview</li>
                  <li>48 Hours Express Shipping</li>
                  <li>One consultation with an immigration attorney to prepare you for your interview</li>
                </ul>
                <p className="font-bold text-xl mb-4">$500</p>
                <button 
                  className="bg-green-500 text-white py-2 px-4 rounded-lg mt-2 w-full" 
                  onClick={() => {
                    setSelectedPlan(50000);  // First state update
                    setPlanName('Hands Free');
                  // Second state update
                }}
                
                >
                  Start Application
                </button>
              </div>
            </div>
          </>
        ) : (
          // Render the PaymentForm if a plan is selected
          <PaymentForm amount={selectedPlan} onClose={onClose} plan={planName} userId={userId} location={location} />
        )}

        <div className="flex justify-center mt-6">
          <button onClick={onClose} className="text-red-500 hover:underline">
            Close
          </button>
        </div>

        <p className="text-center text-xs mt-2 ">*Packages & Pricing do not include required government fees.
           Government fees are paid directly to USCIS upon filing</p>
        <p className="text-xs text-center mt-8">MigrantGenie , Inc is not a law firm.
           The services and information provided are not legal advice
            and do not substitute the same level of advice, opinion, guidance or recommendation
             that a licensed immigration attorney can provide. MigrantGenie is not affiliated with the USCIS. 
             The purchase price of services does not include USCIS government filing fees.</p>
      </div>
    </div>
  );
};




const Dashboard = () => {
  const [darkMode, setDarkMode] = useState(true);
  const [notification, setNotification] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isRenewalModalOpen, setRenewalModalOpen] = useState(false);
  const [isCitizenshipModalOpen, setCitizenshipModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [messages, setMessages] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false)
  const [questionSet, setQuestionSet] = useState([]);
  const [advice, setAdvice] = useState('')
  const [ name, setName ] = useState('')
  const [loading , setLoading] = useState(false)
  const [userId, setUserId] = useState('')
  const [isNotQualifiedModalOpen, setNotQualifiedModalOpen] = useState(false);
  const [questionSetType, setQuestionSetType] = useState("");
  const [recentChats, setRecentChats] = useState([]);
  const [info , setInfo] = useState(null)
  const [location , setLocation] = useState(null)
  const [appType , setAppType] = useState('')
  const [paid , setPaid] = useState(false)
  const [renewalQuestionModal, setRenewalQuestionModal] = useState(false)
  const [workQuestionModal , setWorkQuestionModal] = useState(false)
  const [removalQuestionModal , setRemovalQuestionModal] = useState(false)
  const [citizenshipQuestionModal, setCitizenshipQuestionModal] = useState(false)
  const [link , setLink] = useState(null)
  const navigate = useNavigate();


  useEffect(() => {
    
    const storedName = sessionStorage.getItem('name');
    const storedId = sessionStorage.getItem('userId');
    const cleanedId = storedId.replace(/['",]/g, '')
    setUserId(cleanedId)

    if (storedName) {
      // If a userId is found, update the state
      const cleaneduser = storedName.replace(/['",]/g, '')
      setName(cleaneduser);
    
    } else {
      console.log('No userId found in sessionStorage.');
    }
  }, []);
  useEffect(() => {
    const storedId = sessionStorage.getItem('userId');
    if (storedId) {
      const cleanedId = storedId.replace(/['",]/g, '');
      setUserId(cleanedId);
    }
  
    if (userId) {
      const fetchRecentChats = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/recent/chat/${userId}`);
          if (!response.ok) {
            throw new Error('Failed to fetch recent chats');
          }
  
          const data = await response.json();
          setRecentChats(data);
        } catch (error) {
          console.error('Error fetching recent chats:', error);
        }
      };
  
      const fetchInfo = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/user/${userId}`);
          if (!response.ok) {
            throw new Error('Failed to fetch user info');
          }
  
          const data = await response.json();
          console.log(data.subscribed);
          setInfo(data);
          if(data.subscribed){
            setPaid(true)
          }
        } catch (error) {
          console.error('Error fetching user info:', error);
        }
      };
  
      fetchRecentChats();
      fetchInfo();
    }
  }, [userId]);
  
  const questions = [
    { questionText: "Is the immigrant currently in the United States?", type: "yesno", correctAnswer: "Yes" , isPreference: false },
    { questionText: "Is the immigrant applying for a Green Card based on family, employment, or another category?", type: "multiple", options: ["Family-Based", "Employment-Based", "Other"], isPreference: true},
    { questionText: "Who is the immigrant’s sponsor?", type: "multiple", options: ["Spouse", "Parent", "Sibling"], isPreference:true },
    { questionText: "Is the sponsor a U.S. Citizen or Green Card holder?", type: "yesno", correctAnswer: "Yes" , isPreference: false }
  ];

  const Workquestions = [
    { questionText: "Are you the employee with an approved I-140 or are you a representative of company that is doing this process for your employee?", type: "multiple", options: ["I am the Employer", "I am the Employee"], isPreference:true },
  ];

  const renewalQuestions = [
    { questionText: "Does the immigrant currently have a Green Card?", type: "yesno", correctAnswer: "Yes" , isPreference: false },
    { questionText: "Is the immigrant currently in the US?", type: "yesno", correctAnswer: "Yes" , isPreference: false },
    { questionText: "Is the immigrant’s Green Card for 10 years or 2 years?", type: "multiple", options: ["10 years", "2 years"], isPreference: true }
  ];

  const citizenshipQuestions = [
    { questionText: "Does the immigrant currently have a Green Card?", type: "yesno", correctAnswer: "Yes" , isPreference: false},
    { questionText: "Has the immigrant had their Green Card for at least 4 years & 9 months?", type: "yesno", correctAnswer: "Yes" , isPreference: false},
    { questionText: "Is the immigrant currently in the U.S.?", type: "yesno", correctAnswer: "Yes" , isPreference: false}
  ];
  
 
  

  const hours = new Date().getHours();
  const greeting = hours < 12 ? "Good morning" : hours < 18 ? "Good afternoon" : "Good evening";

  const user = {
    name: "Victor",
    plan: "Professional Plan",
    companyLogo: "https://via.placeholder.com/50", // Replace with actual logo URL
  };

  const NotQualifiedModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
        <div className="bg-gray-800 p-8 rounded-lg w-full max-w-lg">
          <h2 className="text-2xl font-bold mb-4 text-center">Not Qualified</h2>
          <p className="text-center mb-4">
            Based on your answers, you are not qualified to proceed with this application.
          </p>
          <div className="flex justify-center mt-6">
            <button onClick={onClose} className="bg-red-500 text-white py-2 px-4 rounded-lg">
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  
  const handlePrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const resetForm = () => {
    setCurrentStep(0);
    setFormData({});
    setModalOpen(false);
  };


  const handleAnswer = (answer) => {
    const currentQuestion = questionSet[currentStep];
    console.log("appType:", appType);
    console.log("currentStep:", currentStep);
    console.log("paid:", paid);
    // Check if the current question is a preference question
    if (currentQuestion.isPreference) {
        setFormData({ ...formData, [currentStep]: answer });
        
        if (currentStep < questionSet.length - 1) {
            setCurrentStep(currentStep + 1);
        } else {
          if (paid) {
            navigate('/card');
        } else {
            console.log('Setting payment modal open for greenCard');
            setPaymentModalOpen(true);
            setModalOpen(false);
        }
        }
    } else if (currentQuestion.correctAnswer && answer !== currentQuestion.correctAnswer) {
        setNotQualifiedModalOpen(true);
        setModalOpen(false);
    } else {
        setFormData({ ...formData, [currentStep]: answer });
        if (currentStep < questionSet.length - 1) {
            setCurrentStep(currentStep + 1);
        } else {
            
                if (paid) {
                    navigate('/card');
                } else {
                    console.log('Setting payment modal open for greenCard');
                    setPaymentModalOpen(true);
                    setModalOpen(false);
                }
            
        }
    }
};

const handleAnswerRenewal = (answer) => {
    const currentQuestion = questionSet[currentStep];

    // Check if the current question is a preference question
    if (currentQuestion.isPreference) {
        setFormData({ ...formData, [currentStep]: answer });
        
        if (currentStep < questionSet.length - 1) {
            setCurrentStep(currentStep + 1);
        } else {
             if (paid) {
                navigate('/renewal');
            } else {
                setRenewalModalOpen(true); 
                setModalOpen(false);
            }
        }
    } else if (currentQuestion.correctAnswer && answer !== currentQuestion.correctAnswer) {
        setNotQualifiedModalOpen(true);
        setModalOpen(false);
    } else {
        setFormData({ ...formData, [currentStep]: answer });
        if (currentStep < questionSet.length - 1) {
            setCurrentStep(currentStep + 1);
        } else {
            if (paid) {
                navigate('/renewal');
            } else {
                setRenewalModalOpen(true); 
                setModalOpen(false);
            }
        }
    }
};

const handleAnswerCitizenship = (answer) => {
  const currentQuestion = questionSet[currentStep];

  // Check if the current question is a preference question
  if (currentQuestion.isPreference) {
      setFormData({ ...formData, [currentStep]: answer });
      
      if (currentStep < questionSet.length - 1) {
          setCurrentStep(currentStep + 1);
      } else {
           if (paid) {
              navigate('/citizenship');
          } else {
              setRenewalModalOpen(true); 
              setModalOpen(false);
          }
      }
  } else if (currentQuestion.correctAnswer && answer !== currentQuestion.correctAnswer) {
      setNotQualifiedModalOpen(true);
      setModalOpen(false);
  } else {
      setFormData({ ...formData, [currentStep]: answer });
      if (currentStep < questionSet.length - 1) {
          setCurrentStep(currentStep + 1);
      } else {
          if (paid) {
              navigate('/citizenship');
          } else {
              setRenewalModalOpen(true); 
              setModalOpen(false);
          }
      }
  }
};

const handleAnswerWork = (answer) => {
  const currentQuestion = questionSet[currentStep];

  // Check if the current question is a preference question
  if (currentQuestion.isPreference) {
      setFormData({ ...formData, [currentStep]: answer });
      
      if (currentStep < questionSet.length - 1) {
          setCurrentStep(currentStep + 1);
      } else {
           if (paid) {
              navigate('/work');
          } else {
              setRenewalModalOpen(true); 
              setModalOpen(false);
          }
      }
  } else if (currentQuestion.correctAnswer && answer !== currentQuestion.correctAnswer) {
      setNotQualifiedModalOpen(true);
      setModalOpen(false);
  } else {
      setFormData({ ...formData, [currentStep]: answer });
      if (currentStep < questionSet.length - 1) {
          setCurrentStep(currentStep + 1);
      } else {
          if (paid) {
              navigate('/work');
          } else {
              setRenewalModalOpen(true); 
              setModalOpen(false);
          }
      }
  }
};
const handleAnswerRemove = (answer) => {
  const currentQuestion = questionSet[currentStep];

  // Check if the current question is a preference question
  if (currentQuestion.isPreference) {
      setFormData({ ...formData, [currentStep]: answer });
      
      if (currentStep < questionSet.length - 1) {
          setCurrentStep(currentStep + 1);
      } else {
           if (paid) {
              navigate('/removal');
          } else {
              setRenewalModalOpen(true); 
              setModalOpen(false);
          }
      }
  } else if (currentQuestion.correctAnswer && answer !== currentQuestion.correctAnswer) {
      setNotQualifiedModalOpen(true);
      setModalOpen(false);
  } else {
      setFormData({ ...formData, [currentStep]: answer });
      if (currentStep < questionSet.length - 1) {
          setCurrentStep(currentStep + 1);
      } else {
          if (paid) {
              navigate('/removal');
          } else {
              setRenewalModalOpen(true); 
              setModalOpen(false);
          }
      }
  }
};

 

  const handleSetQuestionSet = (selectedQuestions, type) => {
    
     
      setQuestionSet(selectedQuestions);
      setQuestionSetType(type); 
      setCurrentStep(0);
      setModalOpen(true);
    
    
  };
  
  const handleSetQuestionSetRenewal = (selectedQuestions, type) => {
  
      setQuestionSet(selectedQuestions);
      setQuestionSetType(type); 
      setCurrentStep(0);
      setRenewalQuestionModal(true);
    

  };

  const handleSetQuestionSetRemoval = (selectedQuestions, type) => {
    
      setQuestionSet(selectedQuestions);
      setQuestionSetType(type); 
      setCurrentStep(0);
      setRemovalQuestionModal(true);
    

  };
  const handleSetQuestionSetCitizenship = (selectedQuestions, type) => {
   
      setQuestionSet(selectedQuestions);
    setQuestionSetType(type); 
    setCurrentStep(0);
    setCitizenshipQuestionModal(true)
    
    
  };

  const handleSetQuestionWork = (selectedQuestions, type) => {
   
      setQuestionSet(selectedQuestions);
      setQuestionSetType(type); 
      setCurrentStep(0);
      setWorkQuestionModal(true);
      //
    

  };
  // const handleSetQuestionRemove = (selectedQuestions, type) => {
  //   if(!paid){
  //     navigate(`/remove`)
  //   }else{
  //     setQuestionSet(selectedQuestions);
  //     setQuestionSetType(type); 
  //     setCurrentStep(0);
  //     setWorkQuestionModal(true);
  //     //
  //   }

  // };


  const renderQuestion = () => {
    const currentQuestion = questionSet[currentStep];  // Use the active question set
    if (!currentQuestion) return null;
  
    return (
      <div>
        <p className="mb-4">{currentQuestion.questionText}</p>
        {currentQuestion.type === "yesno" && (
          <div className="grid grid-cols-1 gap-4">
            <button className=" border text-white py-2 px-4 rounded-lg shadow-md" onClick={() => handleAnswer("Yes")}>
              Yes
            </button>
            <button className=" border text-white py-2 px-4 rounded-lg shadow-md" onClick={() => handleAnswer("No")}>
              No
            </button>
          </div>
        )}
        {currentQuestion.type === "multiple" && (
          <div className="grid grid-cols-1 gap-4">
            {currentQuestion.options.map((option, index) => (
              <button key={index} className="border text-white py-2 px-4 rounded-lg shadow-md" onClick={() => handleAnswer(option)}>
                {option}
              </button>
            ))}
          </div>
        )}
      </div>
    );
  };

   const renderQuestionRenewal = () => {
    const currentQuestion = questionSet[currentStep];  // Use the active question set
    if (!currentQuestion) return null;
  
    return (
      <div>
        <p className="mb-4">{currentQuestion.questionText}</p>
        {currentQuestion.type === "yesno" && (
          <div className="grid grid-cols-1 gap-4">
            <button className=" border text-white py-2 px-4 rounded-lg shadow-md" onClick={() => handleAnswerRenewal("Yes")}>
              Yes
            </button>
            <button className=" border text-white py-2 px-4 rounded-lg shadow-md" onClick={() => handleAnswerRenewal("No")}>
              No
            </button>
          </div>
        )}
        {currentQuestion.type === "multiple" && (
          <div className="grid grid-cols-1 gap-4">
            {currentQuestion.options.map((option, index) => (
              <button key={index} className="border text-white py-2 px-4 rounded-lg shadow-md" onClick={() => handleAnswerRenewal(option)}>
                {option}
              </button>
            ))}
          </div>
        )}
      </div>
    );
  };
  const renderQuestionCitizenship = () => {
    const currentQuestion = questionSet[currentStep];  // Use the active question set
    if (!currentQuestion) return null;
  
    return (
      <div>
        <p className="mb-4">{currentQuestion.questionText}</p>
        {currentQuestion.type === "yesno" && (
          <div className="grid grid-cols-1 gap-4">
            <button className=" border text-white py-2 px-4 rounded-lg shadow-md" onClick={() => handleAnswerCitizenship("Yes")}>
              Yes
            </button>
            <button className=" border text-white py-2 px-4 rounded-lg shadow-md" onClick={() => handleAnswerCitizenship("No")}>
              No
            </button>
          </div>
        )}
        {currentQuestion.type === "multiple" && (
          <div className="grid grid-cols-1 gap-4">
            {currentQuestion.options.map((option, index) => (
              <button key={index} className="border text-white py-2 px-4 rounded-lg shadow-md" onClick={() => handleAnswerCitizenship(option)}>
                {option}
              </button>
            ))}
          </div>
        )}
      </div>
    );
  };
  const renderQuestionWork = () => {
    const currentQuestion = questionSet[currentStep];  // Use the active question set
    if (!currentQuestion) return null;
  
    return (
      <div>
        <p className="mb-4">{currentQuestion.questionText}</p>
        {currentQuestion.type === "yesno" && (
          <div className="grid grid-cols-1 gap-4">
            <button className=" border text-white py-2 px-4 rounded-lg shadow-md" onClick={() => handleAnswerWork("Yes")}>
              Yes
            </button>
            <button className=" border text-white py-2 px-4 rounded-lg shadow-md" onClick={() => handleAnswerWork("No")}>
              No
            </button>
          </div>
        )}
        {currentQuestion.type === "multiple" && (
          <div className="grid grid-cols-1 gap-4">
            {currentQuestion.options.map((option, index) => (
              <button key={index} className="border text-white py-2 px-4 rounded-lg shadow-md" onClick={() => handleAnswerWork(option)}>
                {option}
              </button>
            ))}
          </div>
        )}
      </div>
    );
  };
  const renderQuestionRemove = () => {
    const currentQuestion = questionSet[currentStep];  // Use the active question set
    if (!currentQuestion) return null;
  
    return (
      <div>
        <p className="mb-4">{currentQuestion.questionText}</p>
        {currentQuestion.type === "yesno" && (
          <div className="grid grid-cols-1 gap-4">
            <button className=" border text-white py-2 px-4 rounded-lg shadow-md" onClick={() => handleAnswerRemove("Yes")}>
              Yes
            </button>
            <button className=" border text-white py-2 px-4 rounded-lg shadow-md" onClick={() => handleAnswerRemove("No")}>
              No
            </button>
          </div>
        )}
        {currentQuestion.type === "multiple" && (
          <div className="grid grid-cols-1 gap-4">
            {currentQuestion.options.map((option, index) => (
              <button key={index} className="border text-white py-2 px-4 rounded-lg shadow-md" onClick={() => handleAnswerRemove(option)}>
                {option}
              </button>
            ))}
          </div>
        )}
      </div>
    );
  };
  


  const handleCheckFilingStatus = async () => {
    try {
      setLoading(true)

      const cleaneduserId = userId.replace(/['",]/g, '')
      console.log(userId)
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/file/${cleaneduserId}`, {
        method: 'GET', // or 'GET', depending on the API
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      // Check if the response is OK
      if (!response.ok) {
        throw new Error('Failed to check filing status');
      }
  
      // Parse the response data
      const data = await response.json();

      navigate('/filling', { state: { advice: data.message } });
      
      // Handle the response data (e.g., update state, display in UI)
      console.log('Filing status response:', data);

    } catch (error) {
      // Handle errors
      console.error('Error checking filing status:', error.message);
    } finally {
      // Hide loading indicator (if necessary)
      console.log('Check filing status complete');
    }
  };

// Handler for "Talk to Case Manager"
const handleTalkToCaseManager = () => {
  const calendlyUrl = 'https://calendly.com/your-calendly-link'; // Replace with your Calendly link
  window.open(calendlyUrl, '_blank'); // Open Calendly in a new tab
};

// Handler for "Submit a Complaint"
const handleSubmitComplaint = () => {
  navigate('/complaint')
  // Your logic to submit a complaint goes here
};

const ApplicationForm = () => {
 

  if (location) {
    // If a location exists, navigate to it
    navigate(`/${location}`)
  }
};
 
  



const handleSubmit = async (e) => {

setLoading(true)
  e.preventDefault(); // Prevent default form submission behavior
  
  try {
    const response = await fetch('https://chat.migrantgenie.com/api/ai/immigration-advice', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ question: inputValue }), // Send user's question in the request body
    });

    console.log({response})
    if (!response.ok) {
      const errorData = await response.json();

      console.log({errorData}) 

      setMessages((prevMessages) => [
        ...prevMessages,
        { text: errorData.message, sender: 'Claude', isError: true }, 
      ]);

      toast.error(`Error: ${errorData.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const data = await response.json(); 
    console.log('Response data:', data); 

    // Process the response data (you can update state here)
    if (data.advice) {
    
      setAdvice(data.advice); 

      const response = await fetch(`${process.env.REACT_APP_API_URL}/open/chat/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content: inputValue, role:'user' }), 
      });

      if (!response.ok) {
        setLoading(false)
        const errorData = await response.json();
  
        console.log({errorData}) 
        
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: errorData.message, sender: 'Claude', isError: true }, 
        ]);
  
        toast.error(`Error: ${errorData.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        throw new Error(`Error: ${response.status} ${response.statusText}`);

    
        
      }
     

      const chatdata = await response.json()

      console.log(chatdata.newChat._id)

      const response2 = await fetch(`${process.env.REACT_APP_API_URL}/chat/${userId}/${chatdata.newChat._id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content: data.advice, role:'ai' }), // Send user's question in the request body
      });
      
      
  
      navigate('/chat', { state: { advice: data.advice, chatId : chatdata.newChat._id } });
    } else {
      console.warn('Unexpected response structure:', data);
    }


  } catch (error) {
    console.error('Error:', error); // Handle any fetch or network errors
  } finally {
    // Cleanup or additional actions can be added here (e.g., stop a loading spinner)
  }
};

const options = [
  {
    text: 'Check Filing Status',
    icon: <FaCheckCircle size={15} />,
    onClick: () => handleCheckFilingStatus(),
  },
  {
    text: 'Talk to Case Manager',
    icon: <FaUser size={15} />,
    onClick: () => handleTalkToCaseManager(),
  },
  {
    text: 'Submit a Complaint',
    icon: <FaExclamationCircle size={15} />,
    onClick: () => handleSubmitComplaint(),
  },

  // {
  //   text: 'Application',
  //   icon: <FaExclamationCircle size={15} />,
  //   onClick: () => ApplicationForm(), // Direct navigation call here
  // },
];

  return (
    <div
      className={`${
        darkMode ? "bg-[#1f1f1f] text-white" : "bg-[#f9e5d8] text-gray-800"
      } min-h-screen p-6 font-sans transition-all relative`}
    >
      { loading && <Spinner/>}
      <ToastContainer/>
      {/* Dark Mode Toggle */}
      <div className="flex justify-end mb-4">
       
      </div>

      {/* Notification */}
      {notification && (
        <div className="fixed top-0 left-0 right-0 bg-green-500 text-white text-center p-4">
          {notification}
        </div>
      )}

      {/* Company Logo (Top-left corner) */}
      <div className="absolute top-2 left-2 ">
        <img
         src="/LogoForMG.png"
          alt="Company Logo"
          className="w-12 h-12 object-contain"
        />
      </div>

      {/* Header */}
      <header className="text-center mb-10">
  <div className="text-4xl font-bold p-2">
    {greeting}, {name}
  </div>
  <div className="mt-2 text-sm text-gray-500 dark:text-gray-400">
    {info ? (
      <>
        Using {info.subscriptionPlan} Plan{" "}
        {/* <Link
          to="/pricing"
          className="underline hover:text-blue-600 dark:text-blue-300 dark:hover:text-blue-400 transition-all duration-300"
        >
          Upgrade
        </Link> */}
      </>
    ) : (
      "Loading user info..."
    )}
  </div>
</header>


      {/* Main Section */}
      <div className="max-w-3xl mx-auto bg-[#2a2a2a] dark:bg-[#333333] shadow-lg rounded-lg p-8 backdrop-blur-lg">
        <p className="text-center">What would you like to do today?</p>
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mb-6 mt-6">
          <motion.button
            whileHover={{ scale: 1.1 }}
            className=" border border-gray-500  text-white py-2 px-4 rounded-lg shadow-md  transform hover:translate-y-1 transition-all duration-300"
            onClick={() => {handleSetQuestionSet(questions, "greenCard"); setLocation('card')}}

          >
            Green Card
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.1 }}
            className="  border border-gray-500 text-white py-2 px-4 rounded-lg shadow-md  transform hover:translate-y-1 transition-all duration-300"
            onClick={() => {handleSetQuestionSetRenewal(renewalQuestions, "renewal") ; setLocation('renewal');  }}
          >
            Renew Green Card
          </motion.button>

          <motion.button
            whileHover={{ scale: 1.1 }}
            className="  border border-gray-500 text-white py-2 px-4 rounded-lg shadow-md  transform hover:translate-y-1 transition-all duration-300"
            onClick={() => {handleSetQuestionSetCitizenship(citizenshipQuestions, "citizenship") ; setLocation('citizenship') ;}}
          >
             US citizenship
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.1 }}
            className="  border border-gray-500 text-white py-2 px-4 rounded-lg shadow-md  transform hover:translate-y-1 transition-all duration-300"
            onClick={() => {handleSetQuestionSetRemoval(renewalQuestions, "removal") ; setLocation('removal') ;}}
          >
            Removal of condition
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.1 }}
            className="  border border-gray-500 text-white py-2 px-4 rounded-lg shadow-md  transform hover:translate-y-1 transition-all duration-300"
            onClick={() => {handleSetQuestionWork(Workquestions, "work") ; setLocation('work') ;}}
          >
            Work Permit
          </motion.button>
        </div>
        {/* Messages Section */}
        <div className="mt-6 max-h-96 overflow-y-auto">
          {messages.map((message, index) => (
            <div key={index} className={`mb-2 flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
              {message.sender === 'Claude' && (
                <img src={user.companyLogo} alt="Company Logo" className="w-8 h-8 object-contain mr-2 self-end" />
              )}
              <div className={`p-2 rounded-lg ${message.sender === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-700 text-white'}`}>
                <span className="text-sm">{message.text}</span>
              </div>
            </div>
          ))}
        </div>

        {/* Input Section */}
        <div className="flex items-center mt-4 relative">
          <input
            type="text"
            value={inputValue}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit(e);  // Call handleSubmit when Enter key is pressed
              }
            }}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Ask Your Immigration Question"
            className="flex-1 p-3 pr-10 border-0 bg-gray-700 dark:bg-gray-800 rounded-lg focus:outline-none focus:ring-0"
          />
          <button 
            type="submit" 
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-blue-500 hover:text-blue-600"
          >
            <>
            <FaPaperPlane size={24} onClick={handleSubmit} />
            </>
          
          </button>
        </div>

        {/* Options Buttons */}
       
      </div>

      {/* Recent Chats Section */}
      <div className="max-w-3xl mx-auto mt-10">
      <h3 className="text-lg font-bold mb-4 text-white dark:text-gray-300">
        Your Recent Chats
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {/* Check if there are no recent chats */}
        {recentChats.length === 0 ? (
          <div className="col-span-1 md:col-span-2 lg:col-span-3 text-center text-gray-500">
            No recent chats
          </div>
        ) : (
          // Chat Items
          recentChats.map((chat) => (
            <motion.div
              key={chat._id}
              whileHover={{ scale: 1.05 }}
              className="bg-gray-800 dark:bg-[#383838] bg-opacity-90 p-4 rounded-lg shadow-md flex justify-between items-center transition-transform duration-300 h-24"
            >
              <div>
                <p className="text-sm text-gray-400">{chat.chatName}</p>
                <span className="text-xs text-gray-500">
                  {new Date(chat.createdAt).toLocaleDateString()}
                </span>
              </div>
              <button
                className="text-blue-500 dark:text-blue-300 text-sm hover:underline hover:text-blue-600 dark:hover:text-blue-400"
                onClick={() => {
                  // Handle view chat action
                  navigate('/chat', { state: {  chatId : chat._id } });
                }}
              >
                View
              </button>
            </motion.div>
          ))
        )}
      </div>
    </div>




      {/* User Profile (Bottom-left corner) */}
      <div className="absolute bottom-4 left-4">
        <motion.div
          whileHover={{ scale: 1.1 }}
          className="cursor-pointer bg-indigo-600 text-white rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold"
          // onClick={() => setModalOpen(true)}
        >
          {name.charAt(0)}
        </motion.div>

        <div className="fixed left-8 top-[80%] md:top-[60%] transform -translate-y-1/2 flex flex-col space-y-4 z-50">
   
        {info?.subscribed && (
  <div>
    {options.map((option, index) => (
      <div
        key={index}
        className="flex rounded-lg p-1 mt-2 bg-[#333333] shadow-lg cursor-pointer"
        onClick={option.onClick} // Attach the onClick handler
      >
        <div className="flex justify-center items-center bg-[#1f1f1f] px-4 py-2">
          {option.icon}
        </div>
        <div className="flex items-center">
          <p className="text-[12px] pl-2 border-l">{option.text}</p>
        </div>
      </div>
    ))}
  </div>
)}

    
    </div>


        {/* Modal */}
 

{isModalOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
    <div className="bg-gray-800 p-8 rounded-lg w-full max-w-lg">
      <h2 className="text-xl font-bold mb-4">Application Questions</h2>
      {/* Render the question dynamically */}
      {renderQuestion()}

      <div className="flex justify-between mt-4">
        <button
          onClick={handlePrevStep}
          disabled={currentStep === 0}
          className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
        >
          Previous Question
        </button>
      </div>

      <button onClick={resetForm} className="mt-4 hover:underline">
        Restart Questions
      </button>
    </div>
  </div>
)}

{renewalQuestionModal && (
  <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
    <div className="bg-gray-800 p-8 rounded-lg w-full max-w-lg">
      <h2 className="text-xl font-bold mb-4">Application Questions</h2>
      {/* Render the question dynamically */}
      {renderQuestionRenewal()}

      <div className="flex justify-between mt-4">
        <button
          onClick={handlePrevStep}
          disabled={currentStep === 0}
          className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
        >
          Previous Question
        </button>
      </div>

      <button onClick={resetForm} className="mt-4 hover:underline">
        Restart Questions
      </button>
    </div>
  </div>
)}

{workQuestionModal && (
  <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
    <div className="bg-gray-800 p-8 rounded-lg w-full max-w-lg">
      <h2 className="text-xl font-bold mb-4">Application Questions</h2>
      {/* Render the question dynamically */}
      {renderQuestionWork()}

      <div className="flex justify-between mt-4">
        <button
          onClick={handlePrevStep}
          disabled={currentStep === 0}
          className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
        >
          Previous Question
        </button>
      </div>

      <button onClick={resetForm} className="mt-4 hover:underline">
        Restart Questions
      </button>
    </div>
  </div>
)}

{removalQuestionModal && (
  <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
    <div className="bg-gray-800 p-8 rounded-lg w-full max-w-lg">
      <h2 className="text-xl font-bold mb-4">Application Questions</h2>
      {/* Render the question dynamically */}
      {renderQuestionRemove()}

      <div className="flex justify-between mt-4">
        <button
          onClick={handlePrevStep}
          disabled={currentStep === 0}
          className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
        >
          Previous Question
        </button>
      </div>

      <button onClick={resetForm} className="mt-4 hover:underline">
        Restart Questions
      </button>
    </div>
  </div>
)}

{citizenshipQuestionModal && (
  <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
    <div className="bg-gray-800 p-8 rounded-lg w-full max-w-lg">
      <h2 className="text-xl font-bold mb-4">Application Questions for Citizenship</h2>
      {/* Render the question dynamically */}
      {renderQuestionCitizenship()}

      <div className="flex justify-between mt-4">
        <button
          onClick={handlePrevStep}
          disabled={currentStep === 0}
          className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
        >
          Previous Question
        </button>
      </div>

      <button onClick={resetForm} className="mt-4 hover:underline">
        Restart Questions
      </button>
    </div>
  </div>
)}



<PaymentModal isOpen={isPaymentModalOpen} onClose={() => setPaymentModalOpen(false)} userId={userId} location = {location}/>
<PaymentModalForRenewal isOpen={isRenewalModalOpen} onClose={() => setRenewalModalOpen(false)} userId={userId} location = {location}/>
      </div>

      {isNotQualifiedModalOpen && (
  <NotQualifiedModal 
    isOpen={isNotQualifiedModalOpen}
    onClose={() => {
      setNotQualifiedModalOpen(false);
      resetForm();  // Optionally reset the form or allow users to correct their answers
    }}
  />
)}

    </div>
  );
};

export default Dashboard;
