// App.js (where routing is set up)
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './home'
import SignUp from './signup'
import Dashboard from './dashboard'
import PricingPage from './pricing';
import Chat from './chat'
import SignIn from './signin'
import ProtectedRoute from './protectedRoute'; 
import GreenCard from './greencard'
import Complaint from './complaint'
import Filling from './filling'
import Interview from './interview'
import Test from './new'
import NewChat from './newChat'
import Renewal from './renewal'
import Citizenship from './citizenship'
import WorkPermit from './workpermit'
import Removal from './removalofcondition'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51NsC7DGGd0XMMIVCbFqTDFax9F3iZXcgixE7GOJLTWwPdTtzhBPTs9NYu0HKrhaIKUAyIVlZsCV3eEE9O3h3S13a00H8pOhdOv');

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<SignUp/>}/>
        <Route path="/login" element={<SignIn/>}/>
        <Route 
          path="/dashboard" 
          element={
            <ProtectedRoute>
             <Elements stripe={stripePromise}>
              <Dashboard />
            </Elements>
            </ProtectedRoute>
          } 
        />
        <Route path="/pricing" element={<PricingPage/>}/>
        <Route path="/chat" element={<Chat/>}/>
        <Route path="/chat/c" element={<NewChat/>}/>
        <Route path="/green" element={<GreenCard/>}/>
        <Route path="/complaint" element={<Complaint/>}/>
        <Route path="/filling" element={<Filling/>}/>
        <Route path="/card" element={<Interview/>}/>
        <Route path="/renewal" element={<Renewal/>}/>
        <Route path="/test" element={<Test/>}/>
        <Route path="/work" element={<WorkPermit/>}/>
        <Route path="/removal" element={<Removal/>}/>
        <Route path="/citizenship" element={<Citizenship/>}/>
      </Routes>
    </Router>
  );
}

export default App;
