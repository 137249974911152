import React, { useState, useEffect } from "react";
import { motion } from "framer-motion"; // For animations
import { FaPaperPlane, FaPlus, FaChevronDown, FaRegComment } from 'react-icons/fa';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { FaCheckCircle, FaUser, FaExclamationCircle } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";


const Dashboard = () => {
  const location = useLocation();
  const [darkMode, setDarkMode] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [messages, setMessages] = useState([]);
  const [isPaneOpen, setPaneOpen] = useState(false); 
  const [isHovered, setHovered] = useState(false); // New state for hover
  const [name, setName] = useState(''); // New state for hover
  const [isTyping, setIsTyping] = useState(false);   
  const [displayedText, setDisplayedText] = useState(""); 
  const navigate = useNavigate();
  const [userId, setUserId] = useState('')
  const [info , setInfo] = useState(null)
  const [recentChats, setRecentChats] = useState([]);

  // Get advice from location state
  const advice = location.state?.advice || '';

  useEffect(() => {
    // Retrieve the userId from sessionStorage
    const storedName = sessionStorage.getItem('name');
    const storedUserId = sessionStorage.getItem('userId');
    setUserId(storedUserId);
    
    if (storedName) {
      // If a userId is found, update the state
      const cleaneduser = storedName.replace(/['",]/g, '')
      setName(cleaneduser);
      console.log(name)
    
    } else {
      console.log('No userId found in sessionStorage.');
    }
  }, []);

  useEffect(() => {
    // Initialize the chat with Claude's advice if available
    if (advice) {
      console.log({advice})
      setMessages([{ text: `${advice} ${name}`, sender: 'Claude' }]); // Set the advice as the first message
    } else {
      setMessages([{ text: "Hi! How can I help you today?", sender: 'Claude' }]); // Default message if no advice
    }
  }, [advice]);

 
  const newPage = ()=>{
    navigate('/chat/c')
  }
  
  useEffect(() => {
    const storedId = sessionStorage.getItem('userId');
    if (storedId) {
      const cleanedId = storedId.replace(/['",]/g, '');
      setUserId(cleanedId);
    }
  
    if (userId) {
      const fetchRecentChats = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/recent/chat/${userId}`);
          if (!response.ok) {
            throw new Error('Failed to fetch recent chats');
          }
  
          const data = await response.json();
          setRecentChats(data);
        } catch (error) {
          console.error('Error fetching recent chats:', error);
        }
      };
  
      const fetchInfo = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/user/${userId}`);
          if (!response.ok) {
            throw new Error('Failed to fetch user info');
          }
  
          const data = await response.json();
          console.log(data);
          setInfo(data);
        } catch (error) {
          console.error('Error fetching user info:', error);
        }
      };
  
      fetchRecentChats();
      fetchInfo();
    }
  }, [userId]);

  // Define the options to be displayed with their respective icons and onClick handlers
  const options = [
    {
      text: 'Check Filing Status',
      icon: <FaCheckCircle size={15} />,
      onClick: () => handleCheckFilingStatus(),
    },
    {
      text: 'Talk to Case Manager',
      icon: <FaUser size={15} />,
      onClick: () => handleTalkToCaseManager(),
    },
    {
      text: 'Submit a Complaint',
      icon: <FaExclamationCircle size={15} />,
      onClick: () => handleSubmitComplaint(),
    },
  ];

  // Handler for "Check Filing Status"
  const handleCheckFilingStatus = async () => {
    try {
      
      const cleaneduserId = userId.replace(/['",]/g, '')
      console.log(userId)
      
      const response = await fetch(`https://api.migrantgenie.com/api/v1/file/${cleaneduserId}`, {
        method: 'GET', // or 'GET', depending on the API
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      // Check if the response is OK
      if (!response.ok) {
        throw new Error('Failed to check filing status');
      }
  
      // Parse the response data
      const data = await response.json();

      navigate('/chat', { state: { advice: data._id } });
      
      // Handle the response data (e.g., update state, display in UI)
      console.log('Filing status response:', data);
  
      // Call the function to update the state or UI with the filing status
      // updateFilingStatus(data.status);
    } catch (error) {
      // Handle errors
      console.error('Error checking filing status:', error.message);
    } finally {
      // Hide loading indicator (if necessary)
      console.log('Check filing status complete');
    }
  };
  

  // Handler for "Talk to Case Manager"
  const handleTalkToCaseManager = () => {
    const calendlyUrl = 'https://calendly.com/your-calendly-link'; // Replace with your Calendly link
    window.open(calendlyUrl, '_blank'); // Open Calendly in a new tab
  };

  // Handler for "Submit a Complaint"
  const handleSubmitComplaint = () => {
    navigate('/complaint'); // Navigate to complaint page
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInputValue('')
    setMessages((prevMessages) => [...prevMessages, { text: inputValue, sender: 'user' }]);
    setIsTyping(true); // Start showing typing indicator

    try {
      const response = await fetch('https://chat.migrantgenie.com/api/ai/immigration-advice', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question: inputValue }), // Send user's question in the request body
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json(); // Parse the JSON response
      // simulateTyping(data);
      // Process the response data and update the conversation
      if (data.advice) {
        setIsTyping(false); // Start showing typing indicator
        setMessages((prevMessages) => [
          ...prevMessages, 
          // { text: inputValue, sender: 'user' }, 
          { text: data.advice, sender: 'Claude' }  // Add Claude's response
        ]);

        setInputValue(''); // Clear input after submitting
      } else {
        console.warn('Unexpected response structure:', data);
      }

    } catch (error) {
      console.error('Error:', error); // Handle any fetch or network errors
    }
  };
 

  return (
    <div className={`flex justify-between min-h-screen h-screen ${darkMode ? "bg-[#2a2a2a]  text-white" : "bg-[#f9e5d8] text-gray-800"} transition-all`}>
      {/* Left Pane with Sliding Animation */}
      <motion.div 
        className={` hidden bg-[#2a2a2a] text-white h-screen w-16 p-4 lg:flex flex-col justify-between transition-all duration-300 ${isPaneOpen ? 'w-64 rounded-lg mt-1 mx-3' : 'w-16'}  ${isHovered ? 'bg-[#444444]' : '#2a2a2a'}`}
        initial={false}
        animate={{ width: isPaneOpen ? "18rem" : "17rem" }}
        onMouseEnter={() => {
          setPaneOpen(true);
          setHovered(true);
        }} 
        onMouseLeave={() => {
          setPaneOpen(false);
          setHovered(false);
        }}
      >
        {/* Top Section */}
        <div className="space-y-4">
          {/* Logo or Header */}
          <div className="flex justify-start">
          <Link to='/dashboard'>
            <img  src="/LogoForMG.png" alt="Logo" className="w-10 h-10 rounded-full" />
            </Link>
          </div>

          {/* Start new chat */}
          {isPaneOpen && (
            <button className="bg-black w-full text-left p-3 flex items-center justify-between rounded-md hover:bg-gray-800" onClick={newPage}>
              <span className="font-semibold">Start new chat</span>
              <FaPlus />
            </button>
          )}

          {/* Starred Chats */}
       

          {/* Recent Chats */}
          {isPaneOpen && (
            <div className="mt-6">
              <h2 className="text-gray-400 mb-2 text-sm font-bold">Recents</h2>
              {recentChats.length === 0 ? (
          <div className="col-span-1 md:col-span-2 lg:col-span-3 text-center text-gray-500">
            No recent chats
          </div>
        ) : (
          // Chat Items
          recentChats.map((chat) => (
            <motion.div
              key={chat._id}
              whileHover={{ scale: 1.05 }}
              className="bg-gray-800 dark:bg-[#383838] bg-opacity-90 p-4 rounded-lg shadow-md flex justify-between items-center transition-transform duration-300 mt-3"
            >
              <div>
                <p className="text-sm text-gray-400">{chat.chatName}</p>
              
              </div>
              <button
                className="text-blue-500 dark:text-blue-300 text-sm hover:underline hover:text-blue-600 dark:hover:text-blue-400"
                onClick={() => {
                  // Handle view chat action
                  navigate('/chat', { state: {  chatId : chat._id } });
                }}
              >
                View
              </button>
            </motion.div>
          ))
        )}
            </div>
          )}
        </div>

        {/* Bottom Section - Profile */}
        <div className="flex items-center justify-start p-4">
          <div className="bg-gray-700 p-2 w-[40px] h-[40px] text-center rounded-full">
            <span className="text-white text-sm font-bold">{name.charAt(0)}</span>
          </div>
          {isPaneOpen && (
            <div className="ml-2">
              <div className="font-bold">{name}</div>
              {info ? (
      
      <div className="text-gray-400 text-sm">{info.subscriptionPlan}</div>
   ) : (
     "Loading user info..."
   )}
            </div>
          )}
        </div>
      </motion.div>

      {/* Middle Pane (Chat Section) */}
      <div className="w-6/6 lg:w-3/6 flex flex-col justify-between p-6 mt-3 rounded">
        {/* Chat Messages */}
        <div
  className="chat-container overflow-y-auto p-6"
  style={{
    scrollbarWidth: 'none',  // Firefox
    msOverflowStyle: 'none', // IE and Edge
    WebkitOverflowScrolling: 'touch',  // For smooth scrolling on touch devices
    overflowX: 'hidden', // Hide horizontal scrollbar just in case
  }}
>
  {messages.map((message, index) => (
  <div key={index} className={`mb-2 flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
  {message.sender === 'Claude' && (
    <img src="/LogoForMG.png"  alt="Company Logo" className="w-8 h-8 object-contain mr-2 self-start" />
  )}
  
  <div className={`p-2 rounded-lg ${message.sender === 'user' ? ' mt-4 mb-4 text-white' : ' text-white'}`}>
    {/* Render advice text with line breaks */}
    {message.sender === 'Claude' ? (
      message.text.split('\n').map((line, idx) => (
        <span key={idx}>
          {line}
          <br />
        </span>
      ))
    ) : (
      <span className="text-sm">{message.text}</span>
    )}
  </div>
</div>

  ))}
  <div className="flex items-center justify-start mb-4">
  {isTyping && (
    <div className="flex space-x-1">
      <span className="w-2 h-2 bg-gray-500 rounded-full animate-typing"></span>
      <span className="w-2 h-2 bg-gray-500 rounded-full animate-typing delay-200"></span>
      <span className="w-2 h-2 bg-gray-500 rounded-full animate-typing delay-400"></span>
    </div>
  )}
</div>

</div>

        {/* Chat Input */}
        <div className="mt-4 mb-6">
          <div className="flex items-center bg-gray-800 rounded-lg">
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit(e);  // Call handleSubmit when Enter key is pressed
                }
              }}
              placeholder="Reply"
              className="flex-1 p-3 bg-gray-700 dark:bg-gray-800 rounded-lg focus:outline-none"
            />
            <button type="submit" onClick={handleSubmit} className="ml-2 hover:text-blue-600 px-4">
              <FaPaperPlane size={24} />
            </button>
          </div>
        </div>
      </div>

      {/* Right Pane */}
      <div className="hidden lg:block w-1/6 p-4 bg-[#2a2a2a] mt-3">
        <div>
          <p className="mb-4 font-bold">Other Activities</p>
          <p className="text-gray-400 text-[13px]">MigrantGenie AI</p>
          <p className="text-gray-400 text-[13px]">Immigration AI</p>
        </div>

       

       
        {
          info?.subscribed && (
            <div>
            <p className="mt-4 text-[10px]">Options</p>
            {options.map((option, index) => (
              <div key={index}
                className="flex rounded-lg p-1 mt-2 bg-[#333333] shadow-lg cursor-pointer"
                onClick={option.onClick} // Attach the onClick handler
              >
                <div className="flex justify-center items-center bg-[#1f1f1f] px-4 py-2">
                  {option.icon}
                </div>
                <div className="flex items-center">
                  <p className="text-[12px] pl-2 border-l">{option.text}</p>
                </div>
              </div>
            ))}
          </div>
          )
        }
      </div>
    </div>
  );
};

export default Dashboard;
