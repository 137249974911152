import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti'; // Import confetti library

// Placeholder logo URL
const logoPlaceholder = "https://via.placeholder.com/150";

// Questions array with types and options for multiple choice
const questions = [
  { text: "What is your current legal name?", type: "text", required: true },
  { text: "Have you ever used any other names?", type: "boolean", required: true },
  { text: "What is your Alien Registration Number (A-Number)?", type: "text", required: true },
  { text: "What is your USCIS Online Account Number (if any)?", type: "text" },
  { text: "What is your date of birth?", type: "text", required: true },
  { text: "What is your sex?", type: "options", options: ["Male", "Female", "Other"], required: true },
  { text: "What is your city or town of birth?", type: "text", required: true },
  { text: "What is your country of birth?", type: "text", required: true },
  { text: "What is your country of citizenship or nationality?", type: "text", required: true },
  { text: "What is your U.S. mailing address?", type: "text", required: true },
];

const ImmigrationForm = () => {
  const { control, handleSubmit, setValue, resetField, getValues } = useForm();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [userId, setUserId] = useState(null); 
  const [showSuccessModal, setShowSuccessModal] = useState(false); 
  const [isSubmitted, setIsSubmitted] = useState(false); 
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve the userId from sessionStorage
    const storedUserId = sessionStorage.getItem('userId');

    if (storedUserId) {
      // If a userId is found, update the state
      setUserId(storedUserId);
    
    } else {
      console.log('No userId found in sessionStorage.');
    }
  }, []);

  const validateInput = (data) => {
    const currentQuestion = questions[currentQuestionIndex];
    if (currentQuestion.required) {
      const answer = data[`question${currentQuestionIndex}`];
      if (!answer || (Array.isArray(answer) && answer.length === 0)) {
        alert(`Please fill in the required field: ${currentQuestion.text}`);
        return false;
      }
    }
    return true;
  };

  const onSubmit = async (data) => {
    const cleaneduserId = userId.replace(/['",]/g, '')
    console.log(userId)
    // Validate current question input
    if (!validateInput(data)) {
      return;
    }

    // Capture the current answer
    setAnswers((prev) => ({
      ...prev,
      [`question${currentQuestionIndex}`]: data[`question${currentQuestionIndex}`],
    }));

    // Prepare the data to send to the backend
    const finalAnswers = questions.map((question, index) => {
      const answer = answers[`question${index}`];
      return { question: question.text, answers: answer ? [answer] : [] }; 
    });

    // If it's the last question, submit the form data to the backend
    if (currentQuestionIndex === questions.length - 1) {
      try {
        const response = await fetch(`https://api.migrantgenie.com/api/v1/question/${cleaneduserId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ questions: finalAnswers }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        console.log('Form submitted successfully:', result);

        // Show the success modal and hide the form
        setShowSuccessModal(true);
        setIsSubmitted(true); // Mark form as submitted

      } catch (error) {
        console.error('Error submitting form:', error);
      }
    } else {
      // Proceed to the next question
      handleNext(data);
    }
  };

  const handleNext = (data) => {
    // Validate current question input before moving to the next question
    if (!validateInput(data)) {
      return;
    }

    setAnswers((prev) => ({
      ...prev,
      [`question${currentQuestionIndex}`]: data[`question${currentQuestionIndex}`],
    }));

    resetField(`question${currentQuestionIndex}`);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleYesNo = (answer) => {
    setAnswers((prev) => ({
      ...prev,
      [`question${currentQuestionIndex}`]: answer,
    }));

    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleOptionSelect = (value) => {
    setValue(`question${currentQuestionIndex}`, value); // Set the selected value
  };

  const handleNextOption = () => {
    const data = getValues(); // Get the current form values
    if (validateInput(data)) { // Validate input
      handleNext(data); // Move to the next question
    }
  };

  const closeModalAndNavigate = () => {
    setShowSuccessModal(false);
    navigate('/chat'); // Navigate to the chat page
  };

  // Get window dimensions for confetti
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    const handleResize = () => setDimensions({ width: window.innerWidth, height: window.innerHeight });
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="flex flex-col items-center bg-[#1f1f1f] text-[white] min-h-screen py-6">
      <img  src="/LogoForMG.png" alt="Logo" className="w-32 mb-6" />
      <h2 className="text-2xl font-bold mb-4">Green Card Application</h2>

      {/* Only show form if not submitted */}
      {!isSubmitted && (
        <div className="w-full max-w-md bg-black bg-opacity-80 z-50 shadow-md rounded-lg p-6">
          <div className="mb-4">
            <div className="flex justify-between mb-2">
              <span>Question {currentQuestionIndex + 1} of {questions.length}</span>
              <span className="font-bold">{Math.round(((currentQuestionIndex + 1) / questions.length) * 100)}%</span>
            </div>
            <div className="h-2 bg-blue-500 rounded">
              <div
                className="h-full bg-blue-600"
                style={{ width: `${((currentQuestionIndex + 1) / questions.length) * 100}%` }}
              ></div>
            </div>
          </div>

          <form onSubmit={handleSubmit(currentQuestionIndex === questions.length - 1 ? onSubmit : handleNext)}>
            <div className="mb-4">
              <label className="block mb-2 font-medium">{questions[currentQuestionIndex].text}</label>

              {/* Dynamic rendering based on question type */}
              {questions[currentQuestionIndex].type === "text" ? (
                <Controller
                  name={`question${currentQuestionIndex}`}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      {...field}
                      className="border p-2 w-full text-[black] rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                  )}
                />
              ) : questions[currentQuestionIndex].type === "boolean" ? (
                <div className="">
                  <button
                    type="button"
                    onClick={() => handleYesNo("Yes")}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200 w-[100%]"
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => handleYesNo("No")}
                    className=" mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-200 w-[100%]"
                  >
                    No
                  </button>
                </div>
              ) : questions[currentQuestionIndex].type === "options" ? (
                <div>
                  {questions[currentQuestionIndex].options.map((option, index) => (
                    <label key={index} className="block mb-2">
                      <input
                        type="radio"
                        value={option}
                        onChange={() => handleOptionSelect(option)} // Set the value when selected
                        className="mr-2"
                      />
                      {option}
                    </label>
                  ))}
                  {/* Next button for options */}
                  <button
                    type="button"
                    onClick={handleNextOption} // Use this to validate and move to next
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200 mt-4"
                  >
                    Next
                  </button>
                </div>
              ) : null}
            </div>

            <div className="flex justify-between">
              {currentQuestionIndex < questions.length - 1 && questions[currentQuestionIndex].type === "text" && (
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200"
                >
                  Next
                </button>
              )}

              {currentQuestionIndex === questions.length - 1 && (
                <button
                  type="submit"
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200"
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      )}

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center text-[black] justify-center">
          <div className="bg-white p-6 rounded-lg text-center">
            <h2 className="text-2xl font-bold mb-4">Congratulations!</h2>
            <p>Your application has been successfully submitted.</p>
            <button
              onClick={closeModalAndNavigate} // Close the modal and navigate to chat page
              className="bg-blue-500 text-white px-4 py-2 mt-4 rounded hover:bg-blue-600"
            >
              Go to Chat
            </button>
          </div>
        </div>
      )}

      {/* Confetti */}
      {showSuccessModal && (
        <Confetti
          width={dimensions.width}
          height={dimensions.height}
        />
      )}
    </div>
  );
};

export default ImmigrationForm;
