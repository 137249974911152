import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import questions from "./citizenShipQuestion";
import { FileIcon, ImageIcon, Send, Plus, User, CheckCircle, AlertCircle } from 'lucide-react';

const FormQuestion = ({ question, value, onChange, onSkip }) => {
  const fileInputRef = useRef(null);

  if (!question) return null;

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Call onChange with the actual file
      onChange(file);
      
      // Reset the input value to allow uploading the same file again if needed
      e.target.value = '';
    }
  };

  const handleUploadClick = (e) => {
    e.preventDefault(); // Prevent any default form submission
    e.stopPropagation(); // Stop event bubbling
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  const renderQuestionContent = () => {
    switch (question.type) {
      case 'image_upload':
        return (
          <div className="space-y-4">
            {value ? (
              // Display uploaded file info
              <div className="flex items-center space-x-3 p-3 bg-gray-700 rounded-lg">
                <ImageIcon className="text-blue-400" size={24} />
                <span className="text-sm truncate">{value.name}</span>
                <button
                  onClick={() => onChange(null)}
                  className="ml-auto text-gray-400 hover:text-white"
                >
                  <AlertCircle size={16} />
                </button>
              </div>
            ) : (
              // Upload button
              <div className="space-y-2">
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                  accept="image/*"
                  className="hidden"
                  aria-label="Upload image"
                />
                <div
                  role="button"
                  tabIndex={0}
                  onClick={handleUploadClick}
                  onKeyPress={(e) => e.key === 'Enter' && handleUploadClick(e)}
                  className="w-full flex flex-col items-center justify-center space-y-2 p-6 border-2 border-dashed border-gray-600 rounded-lg hover:border-blue-500 transition-colors cursor-pointer"
                >
                  <ImageIcon size={32} className="text-gray-400" />
                  <span className="text-sm text-gray-300">Click to upload image</span>
                  <span className="text-xs text-gray-500">Supports: JPG, PNG, GIF</span>
                </div>
              </div>
            )}
          </div>
        );

      case 'single_select':
        return (
          <div className="space-y-2">
            {question.options.map((option, index) => (
              <label key={index} className="flex items-center space-x-2 cursor-pointer hover:bg-gray-700 p-2 rounded">
                <input
                  type="radio"
                  name={question.id}
                  checked={value === option.value}
                  onChange={() => onChange(option.value)}
                  className="form-radio h-4 w-4 text-blue-600"
                />
                <div className="flex flex-col">
                  <span className="text-sm">{typeof option === 'string' ? option : option.value}</span>
                  {typeof option === 'object' && option.helper_text && (
                    <span className="text-xs text-gray-400">{option.helper_text}</span>
                  )}
                </div>
              </label>
            ))}
          </div>
        );

      case 'yes_no':
        return (
          <div className="space-x-4">
            <label className="inline-flex items-center space-x-2 cursor-pointer">
              <input
                type="radio"
                name={question.id}
                checked={value === true}
                onChange={() => onChange(true)}
                className="form-radio h-4 w-4 text-blue-600"
              />
              <span className="text-sm">Yes</span>
            </label>
            <label className="inline-flex items-center space-x-2 cursor-pointer">
              <input
                type="radio"
                name={question.id}
                checked={value === false}
                onChange={() => onChange(false)}
                className="form-radio h-4 w-4 text-blue-600"
              />
              <span className="text-sm">No</span>
            </label>
          </div>
        );

      case 'text_fields':
        return (
          <div className="space-y-2">
            {question.subfields.map((field, index) => (
              <div key={index} className="flex flex-col">
                <label className="text-sm text-gray-400 mb-1">{field.field || field}</label>
                <input
                  type="text"
                  value={value?.[field.field || field] || ''}
                  onChange={(e) => {
                    const newValue = { ...(value || {}), [field.field || field]: e.target.value };
                    onChange(newValue);
                  }}
                  className="p-2 bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder={field.field || field}
                  required={field.required}
                />
              </div>
            ))}
          </div>
        );

      default:
        return (
          <input
            type="text"
            value={value || ''}
            onChange={(e) => onChange(e.target.value)}
            className="w-full p-2 bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Type your answer..."
          />
        );
    }
  };

  return (
     <div className="space-y-4">
      {renderQuestionContent()}
      {question.skipable && (
        <button
          onClick={onSkip}
          className="mt-2 px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors"
        >
          Skip this question
        </button>
      )}
    </div>
  );
};



const Dashboard = () => {
  const [messages, setMessages] = useState([]);
  const [currentPart, setCurrentPart] = useState(1);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1); // -1 represents showing intro
  const [answers, setAnswers] = useState({});
  const [isTyping, setIsTyping] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [showingIntro, setShowingIntro] = useState(true);
  const [userId, setUserId] = useState(null);
  const [isCompleted , setIsCompleted] = useState(true)
  const [info , setInfo] = useState(null)
  const [name , setName] = useState('')
  const [recentChats , setRecentChats] = useState([])
  const navigate = useNavigate();
  const chatEndRef = useRef(null);
  

  
  const handleSkip = () => {
    const currentQuestion = getCurrentPartQuestions()[currentQuestionIndex];
    const questionKey = `part${currentPart}_${currentQuestion.id}`;
    
    // Mark the question as skipped in answers
    setAnswers(prev => ({
      ...prev,
      [questionKey]: "SKIPPED"
    }));

    // Add skip message to chat
    setMessages(prev => [
      ...prev,
      {
        text: "Skipped",
        sender: "user"
      }
    ]);

    // Move to next question
    handleNextQuestion();
  };

  const handleNextQuestion = () => {
    const currentPartQuestions = getCurrentPartQuestions();
    let nextQuestionIndex = currentQuestionIndex + 1;
    
    if (nextQuestionIndex < currentPartQuestions.length) {
      const nextQuestion = currentPartQuestions[nextQuestionIndex];
      setMessages(prev => [...prev, {
        text: nextQuestion.question,
        sender: "Claude"
      }]);
      setCurrentQuestionIndex(nextQuestionIndex);
    } else {
      // Handle end of part
      if (currentPart < questions.length) {
        setCurrentPart(currentPart + 1);
        setShowingIntro(true);
        setCurrentQuestionIndex(-1);
        setMessages(prev => [...prev, {
          text: questions[currentPart].partIntro,
          sender: "Claude"
        }]);
      } else {
        setMessages(prev => [...prev, {
          text: "Thank you for completing the form.",
          sender: "Claude"
        }]);
      }
    }
  };

  useEffect(() => {
    if (isInitialized) return;
    const storedId = sessionStorage.getItem('userId');
    const storedName = sessionStorage.getItem('name');
    const cleaneduser = storedName.replace(/['",]/g, '')
    setName(cleaneduser);
    console.log(storedId)
    let cleanedId
    if (storedId) {
      cleanedId = storedId.replace(/['",]/g, '');
      console.log(cleanedId)
      setUserId(cleanedId);
    }
    
    const fetchRecentChats = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/recent/chat/${cleanedId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch recent chats');
        }

        const data = await response.json();
        console.log(data)
        setRecentChats(data);
      } catch (error) {
        console.error('Error fetching recent chats:', error);
      }
    };

    const fetchInfo = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user/${cleanedId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch user info');
        }

        const data = await response.json();
        console.log(data);
        setInfo(data);
      
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    const savedStateStr = localStorage.getItem('citizenshipformState');
    if (savedStateStr) {
      try {
        const savedState = JSON.parse(savedStateStr);
        setAnswers(savedState.answers || {});
        setCurrentPart(savedState.currentPart || 1);
        setCurrentQuestionIndex(savedState.currentQuestionIndex || -1);
        setShowingIntro(savedState.showingIntro !== false);
        setMessages(savedState.messages || [{ 
          text: questions[0].partIntro, 
          sender: "Claude" 
        }]);
      } catch (error) {
        console.error("Error loading saved state:", error);
        setMessages([{ text: questions[0].partIntro, sender: "Claude" }]);
      }
    } else {
      setMessages([{ text: questions[0].partIntro, sender: "Claude" }]);
    }
    
    setIsInitialized(true);
    fetchRecentChats()
    fetchInfo()
  }, [isInitialized, questions]);

  useEffect(() => {
    if (!isInitialized) return;
    
    const formState = {
      answers,
      currentPart,
      currentQuestionIndex,
      showingIntro,
      messages
    };
    
    localStorage.setItem('citizenshipformState', JSON.stringify(formState));
  }, [answers, currentPart, currentQuestionIndex, showingIntro, messages, isInitialized]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  



const sendPartToBackend = async (partNumber) => {
  if (!userId) {
    console.error('No userId found');
    return;
  }

  try {
    const currentPartQuestions = questions.find(q => q.Part === partNumber)?.questionfor || [];
    const formData = new FormData();

    // Add debugging logs
    console.log('Current answers:', answers);

    const formattedQuestions = currentPartQuestions.map((questionObj, index) => {
      const answerKey = `part${partNumber}_${questionObj.id}`;
      const answer = answers[answerKey];

      // Debug log for each question
      console.log(`Processing question:`, {
        id: questionObj.id,
        type: questionObj.type,
        answer: answer
      });

      if (answer === "SKIPPED") {
        return {
          question: questionObj.question,
          answer: "SKIPPED"
        };
      }

      // Handle different question types
      switch (questionObj.type) {
        case 'image_upload':
          if (answer instanceof File) {
            const fieldName = `file_${questionObj.id}`;  // Changed to use question ID for unique file names
            formData.append(fieldName, answer);
            console.log('Appending file to FormData:', fieldName, answer); // Debug log
            return {
              question: questionObj.question,
              type: 'image_upload',
              answer: fieldName // Store reference to the file field name
            };
          }
          console.log('No file found for image upload question:', questionObj.id);
          return {
            question: questionObj.question,
            type: 'image_upload',
            answer: null
          };

        case 'text_fields':
          return {
            question: questionObj.question,
            type: 'text_fields',
            answer: answer
          };

        case 'single_select':
          return {
            question: questionObj.question,
            type: 'single_select',
            answer: answer
          };

        case 'yes_no':
          return {
            question: questionObj.question,
            type: 'yes_no',
            answer: answer
          };

        default:
          return {
            question: questionObj.question,
            type: 'text',
            answer: answer
          };
      }
    });

    // Filter out any null/undefined entries
    const validQuestions = formattedQuestions.filter(q => q !== null);

    // Add the questions data to FormData
    formData.append('questions', JSON.stringify(validQuestions));

    // Debug log the FormData contents
    console.log('FormData contents:');
    for (let pair of formData.entries()) {
      console.log(pair[0], typeof pair[1], pair[1] instanceof File ? 'File' : pair[1]);
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}/citizenship/question/${userId}`, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;

  } catch (error) {
    console.error(`Error sending Part ${partNumber} data:`, error);
    throw error;
  }
};
  
  

   const handleAnswerChange = async (value) => {
    const currentQuestion = getCurrentPartQuestions()[currentQuestionIndex];
    const questionKey = `part${currentPart}_${currentQuestion.id}`;
    
    // Store the value directly, since for image uploads we're now passing the file directly
    setAnswers(prev => ({
      ...prev,
      [questionKey]: value
    }));
  
    if (currentQuestion.type === 'image_upload') {
      setMessages(prev => [
        ...prev,
        {
          text: `File uploaded: ${value?.name}`,
          sender: "user",
          isTemp: true
        }
      ]);
    }
  };
  

  const getCurrentPartQuestions = () => {
    const currentPartData = questions.find(q => q.Part === currentPart);
    let currentQuestions = currentPartData?.questionfor || [];
    
    // Process dependent questions
    let processedQuestions = [];
    for (let question of currentQuestions) {
      processedQuestions.push(question);
      
      if (question.dependentQuestions) {
        const dependentQuestions = question.dependentQuestions.filter(dq => {
          return dq.condition(answers);
        });
        processedQuestions = [...processedQuestions, ...dependentQuestions];
      }
    }
    
    return processedQuestions;
  };

  
  const onSubmit = async () => {
    const currentPartQuestions = getCurrentPartQuestions();
    const currentQuestion = currentPartQuestions[currentQuestionIndex];
    const questionKey = `part${currentPart}_${currentQuestion.id}`;
    const currentAnswer = answers[questionKey];
  
    if (currentQuestion?.required && !currentAnswer && currentAnswer !== "SKIPPED") {
      setMessages(prev => [...prev, {
        text: "Please provide an answer before proceeding.",
        sender: "Claude"
      }]);
      return;
    }
  
    // Remove temporary message if it exists
    setMessages(prev => prev.filter(msg => !msg.isTemp));
  
    // Add the final answer to messages
    if (!showingIntro && currentAnswer !== "SKIPPED") {
      let displayText;
      if (currentQuestion.type === 'image_upload' && currentAnswer) {
        displayText = `Uploaded: ${currentAnswer.name}`;
      } else {
        displayText = typeof currentAnswer === 'object' ? 
          Object.values(currentAnswer).join(' ') : 
          currentAnswer?.toString();
      }
  
      setMessages(prev => [...prev, {
        text: displayText,
        sender: "user"
      }]);
    }
  
    try {
      setIsTyping(true);
      
      // Send current part to backend when all questions are answered
      if (currentQuestionIndex === getCurrentPartQuestions().length - 1) {
        await sendPartToBackend(currentPart);
      }
  
      setTimeout(() => {
        handleNextQuestion();
        setIsTyping(false);
      }, 1000);
    } catch (error) {
      console.error('Error submitting form:', error);
      setMessages(prev => [...prev, {
        text: "Error submitting form. Please try again.",
        sender: "Claude"
      }]);
      setIsTyping(false);
    }
  };



  const handleContinue = () => {
    if (showingIntro) {
      setShowingIntro(false);
      setCurrentQuestionIndex(0);
      setMessages(prev => [...prev, { 
        text: getCurrentPartQuestions()[0].question, 
        sender: "Claude" 
      }]);
    }
  };


  const handleNewForm = () => {
    if (window.confirm("Are you sure you want to start a new form? Your current progress will be lost.")) {
      localStorage.removeItem('citizenshipformState');
      setAnswers({});
      setCurrentPart(1);
      setCurrentQuestionIndex(-1);
      setShowingIntro(true);
      setMessages([{ text: questions[0].partIntro, sender: "Claude" }]);
    }
  };

  const currentQuestion = showingIntro ? null : getCurrentPartQuestions()[currentQuestionIndex];

  return (
    <div className="flex h-screen bg-gray-900 text-white">
    {/* Left Sidebar */}
    <div className="w-64 bg-gray-800 p-4 hidden lg:block">
      <div className="flex items-center space-x-2 mb-6">
        <img src="/LogoForMG.png" alt="Logo" className="w-10 h-10 rounded-full" />
        <span className="font-bold">MigrantGenie</span>
      </div>
      <button 
        className="w-full bg-blue-600 text-white rounded-lg p-2 flex items-center justify-center space-x-2 mb-4"
        onClick={handleNewForm}
      >
        <Plus size={16} />
        <span>New Form</span>
      </button>
      <div className="mt-6">
              <h2 className="text-gray-400 mb-2 text-sm font-bold">Recents</h2>
              {recentChats.length === 0 ? (
          <div className="col-span-1 md:col-span-2 lg:col-span-3 text-center text-gray-500">
            No recent chats
          </div>
        ) : (
          // Chat Items
          recentChats.map((chat) => (
            <div
              key={chat._id}
              whileHover={{ scale: 1.05 }}
              className="bg-gray-800 dark:bg-[#383838] bg-opacity-90 p-4 rounded-lg shadow-md flex justify-between items-center transition-transform duration-300 mt-3"
            >
              <div>
                <p className="text-sm text-gray-400">{chat.chatName}</p>
              
              </div>
              <button
                className="text-blue-500 dark:text-blue-300 text-sm hover:underline hover:text-blue-600 dark:hover:text-blue-400"
                onClick={() => {
                  // Handle view chat action
                  navigate('/chat', { state: {  chatId : chat._id } });
                }}
              >
                View
              </button>
            </div>
          ))
        )}
      </div>
      <div className="flex items-center justify-start p-4 absolute bottom-0">
          <div className="bg-gray-700 p-2 w-[40px] h-[40px] text-center rounded-full">
            <span className="text-white text-sm font-bold">{name.charAt(0)}</span>
          </div>
        
            <div className="ml-2">
              <div className="font-bold">{name}</div>
              {info ? (
      
      <div className="text-gray-400 text-sm">{info.subscriptionPlan}</div>
   ) : (
     "Loading user info..."
   )}
            </div>
          
        </div>
    </div>

    {/* Main Content Area */}
    <div className="flex-1 flex flex-col p-6">
      <div className="flex-1 overflow-y-auto mb-4">
        {messages.map((message, index) => (
          <div 
            key={index} 
            className={`message mb-4 ${message.sender === "user" ? "text-right" : "text-left"}`}
          >
            <div className={`inline-block p-3 rounded-lg ${
              message.sender === "user" ? "bg-blue-600" : "bg-gray-700"
            }`}>
              {message.text}
              {currentQuestion?.helper_text && message.sender === "Claude" && (
                <div className="text-sm text-gray-400 mt-2">
                  {currentQuestion.helper_text}
                </div>
              )}
            </div>
          </div>
        ))}
        {isTyping && (
          <div className="flex items-center space-x-2 text-gray-400">
            <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"/>
            <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-100"/>
            <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-200"/>
          </div>
        )}
        <div ref={chatEndRef} />
      </div>
      
      <div className="mt-4">
        {isCompleted && (
          showingIntro ? (
            <button
              onClick={handleContinue}
              className="w-full px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors flex items-center justify-center space-x-2"
            >
              <span>Continue to Part {currentPart}</span>
            </button>
          ) : (
            <>
              <FormQuestion
                question={currentQuestion}
                value={answers[`part${currentPart}_${currentQuestion?.id}`]}
                onChange={handleAnswerChange}
                onSkip={currentQuestion?.skipable ? handleSkip : undefined}
              />
              <button
                onClick={onSubmit}
                disabled={isTyping}
                className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors flex items-center justify-center space-x-2"
              >
                <Send size={16} />
                <span>Submit</span>
              </button>
            </>
          )
        )}
      </div>
    </div>

    {/* Right Sidebar */}
    <div className="w-64 bg-gray-800 p-4 hidden lg:block">
      <div className="space-y-4">
        {/* Progress Section */}
        <div className="border-b border-gray-700 pb-4">
          <h3 className="text-sm font-semibold text-gray-400 mb-2">Progress</h3>
          <div className="flex items-center space-x-2">
            <div className="h-2 flex-1 bg-gray-700 rounded-full overflow-hidden">
              <div 
                className="h-full bg-blue-600 rounded-full"
                style={{ 
                  width: `${(currentPart / questions.length) * 100}%` 
                }}
              />
            </div>
            <span className="text-sm text-gray-400">
              Part {currentPart}/{questions.length}
            </span>
          </div>
        </div>

        {/* Current Part Summary */}
        <div className="border-b border-gray-700 pb-4">
          <h3 className="text-sm font-semibold text-gray-400 mb-2">Current Part</h3>
          <p className="text-sm text-gray-300">
            {questions[currentPart - 1]?.partTitle || `Part ${currentPart}`}
          </p>
        </div>

        {/* Answered Questions Summary */}
        <div>
          <h3 className="text-sm font-semibold text-gray-400 mb-2">Questions Answered</h3>
          <div className="space-y-2">
            {getCurrentPartQuestions().map((question, index) => {
              const questionKey = `part${currentPart}_${question.id}`;
              const isAnswered = answers[questionKey] !== undefined;
              const isSkipped = answers[questionKey] === "SKIPPED";
              return (
                <div 
                  key={index}
                  className="flex items-center space-x-2 text-sm"
                >
                  {isAnswered ? (
                    isSkipped ? (
                      <AlertCircle size={16} className="text-yellow-500" />
                    ) : (
                      <CheckCircle size={16} className="text-green-500" />
                    )
                  ) : (
                    <div className="w-4 h-4 rounded-full border border-gray-600" />
                  )}
                  <span className={`truncate ${
                    isAnswered 
                      ? isSkipped 
                        ? "text-yellow-500"
                        : "text-green-500"
                      : "text-gray-500"
                  }`}>
                    Question {index + 1}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  </div>
);
};

export default Dashboard;
