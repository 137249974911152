import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Spinner from "./spinner";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const LoginPage = () => {

  const [name, setName] = useState('')
  const [password, setPassword] = useState("")
  const [email , setEmail] = useState("")
  const [loading , setLoading] = useState(false)
  const navigate = useNavigate();


  const handleRegistration = async (e) => {
    setLoading(true);
    e.preventDefault(); // Prevent the default form submission behavior
    const userData = { name, email, password };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.message)
      }

      const result = await response.json();
      console.log('Registration successful:', result);

      // Store the user data in session storage
      sessionStorage.setItem('userEmail', JSON.stringify(result.email)); // Adjust according to the structure of your API response
      sessionStorage.setItem('userId', JSON.stringify(result.id)); // Adjust according to the structure of your API response
      sessionStorage.setItem('token', JSON.stringify(result.token)); // Adjust according to the structure of your API response
      sessionStorage.setItem('name', JSON.stringify(result.name)); // Adjust according to the structure of your API response

      // Navigate to the dashboard
      navigate('/dashboard');// Assuming your dashboard route is /dashboard
    } catch (error) {
      console.error('Error during registration:', error);
      // Handle error (e.g., display error message to the user)
    } finally {
      setLoading(false); // Ensure loading state is reset in both success and error scenarios
    }
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row font-sans">
         <ToastContainer />
            {loading && <Spinner />} 
      {/* Left section (Intro + Form) */}
      <div
        className="flex-1 flex flex-col justify-center items-center p-8 shadow-lg rounded-none md:rounded-r-lg"
        style={{ backgroundColor: "#128764", borderRadius: "20px" }}
      >
        {/* Intro Section */}
        <div className="text-center mb-8">
          {/* Logo */}
          <div className="flex justify-center mb-6">
            <img
              src="/LogoForMG.png" // Replace with your logo URL
              alt="Logo"
              className="w-20 h-20 object-cover "
            />
          </div>

          {/* Header */}
          <h1 className="text-3xl md:text-4xl font-semibold text-gray-800 mb-2 font-gordita">
          #1 AI Immigration Consultant
          </h1>
          <p className="text-sm text-gray-700">
          We make your American Dream Possible
          </p>
        </div>

        {/* Form Section */}
        <div className="w-full max-w-sm bg-white p-6 md:p-8 rounded-xl shadow-lg">
         
          {/* <button className="flex items-center justify-center w-full bg-white border border-gray-300 py-2 px-4 rounded-md shadow-sm hover:bg-gray-100 transition-all duration-300 mb-4">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/4/4a/Logo_2013_Google.png"
              alt="Google logo"
              className="w-5 h-5 mr-2"
            />
            <span className="text-gray-700">Continue with Google</span>
          </button> */}

          {/* Divider */}
          {/* <div className="flex items-center my-4">
            <div className="flex-grow border-t border-gray-300"></div>
            <span className="px-3 text-gray-500">OR</span>
            <div className="flex-grow border-t border-gray-300"></div>
          </div> */}

          {/* Email Input */}
          <input type="email"
          placeholder="name@email.com" 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full p-3 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-[#face4d]" />

          <input
           type="text" 
           placeholder="john doe" 
           className="w-full p-3 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-[#face4d]"
           value={name}
           onChange={(e) => setName(e.target.value)}
           />

          <input 
          type="password" 
          placeholder="*******" 
          className="w-full p-3 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-[#face4d]"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          />

          {/* Continue with Email Button */}
          <button
            className="w-full bg-[#face4d] text-white py-2 px-4 rounded-md hover:bg-[#f9d542] transition-all duration-300"
            style={{ backgroundColor: "#face4d" }}
            onClick={handleRegistration}
          >
            
            Continue
            
          
          </button>
            <p className="text-sm text-gray-500 mt-4 text-center">Already have an Account ? <a href="/login"> Sign In</a></p>
          {/* Terms and Policy */}
          <p className="text-sm text-gray-500 mt-4 text-center">
            By continuing, you agree to MigrantGenie’s{" "}
            <a href="#" className="underline hover:text-gray-600">
              Consumer Terms
            </a>{" "}
            and{" "}
            <a href="#" className="underline hover:text-gray-600">
              Usage Policy
            </a>
            , and acknowledge their{" "}
            <a href="#" className="underline hover:text-gray-600">
              Privacy Policy
            </a>
            .
          </p>
        </div>

        {/* Learn More Button */}
        <div className="mt-6 text-center">
          {/* <button className="text-gray-700 underline hover:text-gray-900">
            Learn more
          </button> */}
        </div>
      </div>

      {/* Right Section (Image + Floating Stats) */}
      <div className="relative md:w-6/12 w-full flex justify-center p-4 mt-4 md:mt-0 overflow-visible">
        {/* Consultant Image Container */}
        <div className="relative w-full max-w-md md:max-w-none md:w-[90%]">
          {/* Consultant Image */}
          <img
            src="/working woman.jpeg" // Replace with actual image URL
            alt="Consultant"
            className="rounded-lg shadow-lg w-full h-[400px] md:h-[500px] object-cover"
          />

          {/* Floating Stats - Positioned Around the Image */}
          <div className="absolute top-[-20px] left-[5%] bg-white shadow-lg p-3 rounded-lg">
            <p className="text-xl font-bold">10k</p>
            <span className="text-gray-500">Approved Cases</span>
          </div>

          <div className="absolute top-[110px] right-[5%] bg-white shadow-lg p-3 rounded-lg">
            <p className="text-xl font-bold">11k</p>
            <span className="text-gray-500">Applications</span>
          </div>

          <div className="absolute bottom-[-40px] left-[5%] bg-white shadow-lg p-3 rounded-lg">
            <p className="text-xl font-bold">9K</p>
            <span className="text-gray-500">GreenCards</span>
            <div className="flex mt-2 space-x-2">
              {/* Replace these with real avatar images */}
              <img
                src="https://via.placeholder.com/30"
                alt="avatar1"
                className="rounded-full"
              />
              <img
                src="https://via.placeholder.com/30"
                alt="avatar2"
                className="rounded-full"
              />
              <img
                src="https://via.placeholder.com/30"
                alt="avatar3"
                className="rounded-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
