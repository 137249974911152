
    
    const questions = [
      {
        Part: 1,
        partIntro: "Information About You",
        questionfor: [
          {
            id: "1.1",
            question: "Alien Registration Number (A-Number)",
            type: "text",
            skipable:true,
            required: true,
          },
          {
            id: "1.2",
            question: "USCIS Online Account Number",
            type: "text",
            skipable:true,
            required: false
          },
          {
            id: "1.3",
            question: "Your Full Name",
            type: "text_fields",
            subfields: [
              {
                field: "Family Name (Last Name)",
                required: true,
              },
              {
                field: "Given Name (First Name)",
                required: true,
              },
              {
                field: "Middle Name",
                required: false
              }
            ]
          },
          {
            id: "1.4",
            question: "Has your name legally changed since the issuance of your Permanent Resident Card?",
            type: "yes_no"
          },
          {
            id: "1.5",
            question: "Name on Current Permanent Resident Card",
            type: "text_fields",
            subfields: [
              {
                field: "Family Name (Last Name)",
                required: true
              },
              {
                field: "Given Name (First Name)",
                required: true
              },
              {
                field: "Middle Name",
                required: false
              }
            ]
          },
          {
            id: "1.6",
            question: "Current Mailing Address",
            type: "text_fields",
            required: true,
            subfields: [
              {
                field: "In Care Of Name",
                required: false
              },
              {
                field: "Street Number and Name",
                required: true
              },
              {
                field: "Apt/Ste/Flr Number",
                required: false
              },
              {
                field: "City or Town",
                required: true
              },
              {
                field: "State/Province",
                required: true
              },
              {
                field: "ZIP Code/Postal Code",
                required: true
              },
              {
                field: "Country",
                required: true
              }
            ]
          },
          {
            id: "1.7",
            question: "Physical Addresses",
            type: "text_fields",
            required: true,
            subfields: [
              {
                field: "Street Number and Name",
                required: true
              },
              {
                field: "Apt/Ste/Flr Number",
                required: false
              },
              {
                field: "City or Town",
                required: true
              },
              {
                field: "State/Province",
                required: true
              },
              {
                field: "ZIP Code/Postal Code",
                required: true
              },
              {
                field: "Country",
                required: true
              },
              {
                field: "Dates of Residence From",
                type: "date",
                required: true
              },
              {
                field: "Dates of Residence To",
                type: "date",
                required: true
              }
            ]
          },
          {
            id: "1.8",
            question: "Date of Birth",
            type: "date",
            format: "mm/dd/yyyy",
            required: true
          },
          {
            id: "1.9",
            question: "City/Town/Village of Birth",
            type: "text",
            required: true
          },
          {
            id: "1.10",
            question: "Country of Birth",
            type: "text",
            required: true
          },
          {
            id: "1.11",
            question: "Mother's First Name",
            type: "text",
            required: true
          },
          {
            id: "1.12",
            question: "Father's First Name",
            type: "text",
            required: true
          },
          {
            id: "1.13",
            question: "Class of Admission",
            type: "text",
            required: true
          },
          {
            id: "1.14",
            question: "Date of Admission",
            type: "date",
            format: "mm/dd/yyyy",
            required: true
          },
          {
            id: "1.15",
            question: "U.S. Social Security Number",
            type: "text",
            skipable:true,
            required: false
          },
          {
            id: "1.16",
            question: "Gender",
            type: "single_select",
            required: true,
            options: [
                {
                    id:"A",
                    value:"Male"
                },
                {
                    id:"B",
                    value:"Female"
                }
                ]
          }
        ]
      },
      {
        Part: 2,
        partIntro: "Application Type",
        questionfor: [
          {
            id: "2.1",
            question: "My status is",
            type: "single_select",
            required: true,
            options: [
              {
                id:"A",
                value: "Lawful Permanent Resident"
              },
              {
                id:"B",
                value: "Permanent Resident - In Commuter Status"
              },
              {
                id:"C",
                value: "Conditional Permanent Resident"
              }
            ]
          },
          {
            id: "2.2",
            question: "Reason for Application",
            type: "single_select",
            required: true,
            options: [
                {
                    id:"A",
                    value: "My previous card has been lost, stolen, or destroyed"
                  },
                  {
                    id:"B",
                    value: "My previous card was issued but never received"
                  },
                  {
                    id:"C",
                    value: "My existing card has been mutilated"
                  },
                  {
                    id:"D",
                    value: "My existing card has incorrect data because of Department of Homeland Security (DHS) error"
                  },
                  {
                    id:"E",
                    value: "My name or other biographic information has been legally changed since issuance of my existing card"
                  },
                  {
                    id:"F",
                    value: "My existing card has already expired or will expire within six months"
                  },
                  {
                    id:"G",
                    value: "I have reached my 14th birthday and am registering as required. My existing card will expire AFTER my 16th birthday"
                  },
                  {
                    id:"H",
                    value: "I have reached my 14th birthday and am registering as required. My existing card will expire BEFORE my 16th birthday"
                  },
                  {
                    id:"I",
                    value: "I am a permanent resident who is taking up commuter status"
                  },
                  {
                    id:"J",
                    value: "I am a commuter who is taking up actual residence in the United States"
                  },
                  {
                    id:"K",
                    value: "I have been automatically converted to lawful permanent resident status"
                  },
                  {
                    id:"L",
                    value: "I have a prior edition of the Alien Registration Card, or I am applying to replace my current Permanent Resident Card for a reason that is not specified above"
                  }

            ]
          },
         
        ]
      },
      {
        Part: 3,
        partIntro: "Processing Information",
        questionfor: [
          {
            id: "3.1",
            question: "Location where you applied for an immigrant visa or adjustment of status",
            type: "text",
            required: true
          },
          {
            id: "3.2",
            question: "Location where your immigrant visa was issued or USCIS office where you were granted adjustment of status",
            type: "text",
            required: true
          },
          {
            id: "3.3",
            question: "Destination in U.S. at time of admission",
            type: "text",
            required: true
          },
          {
            id: "3.4",
            question: "Have you ever been in exclusion, deportation, or removal proceedings or ordered removed from the United States?",
            type: "yes_no",
            
          },
          {
            id: "3.5",
            question: "Since you were granted permanent residence, have you ever filed Form I-407 Abandonment by Alien of Status as Lawful Permanent Resident, or otherwise been determined to have abandoned your status?",
            type: "yes_no",
            
          },
          {
            id: "3.6.1",
            field: "Ethnicity",
            type: "single_select",
            required: true,
            options: [
                {
                    id:'A',
                    value:'Hispanic or Latino'
                },
                {
                    id:'B',
                    value:'Not Hispanic or Latino'
                }
             
            ]
          },
          {
            id: "3.6.2",
            field: "Race",
            type: "single_select",
            required: true,
            options: [
                {
                    id:'A',
                    value:'White'
                },
                {
                    id:'B',
                    value:'Asian'
                },
                {
                    id:'C',
                    value:'Black or African American'
                },
                {
                    id:'D',
                    value:'American Indian or Alaska Native'
                },
                {
                    id:'E',
                    value:'Native Hawaiian or Other Pacific Islander'
                }
              
            ]
          },
          {
            id: "3.7",
            field: "Height",
            type: "text_fields",
            required: true,
            subfields: [
              {
                field: "Feet",
                type: "number"
              },
              {
                field: "Inches",
                type: "number"
              }
            ]
          },
          {
            id: "3.8",
            field: "Weight",
            type: "number",
            unit: "Pounds",
            required: true
          }
         
        ]
      },
      {
        Part: 4,
        partIntro: "Accommodations for Individuals with Disabilities and/or Impairments",
        questionfor: [
          {
            id: "4.1",
            question: "Are you requesting an accommodation because of your disabilities and/or impairments?",
            type: "yes_no",
            
          },
          {
            id: "4.2",
            question: "I am deaf or hard of hearing and request the following accommodation",
            type: "yes_no"
          },
          {
            id: "4.3",
            question: "I am blind or have low vision and request the following accommodation",
            type: "yes_no"
          },
          {
            id: "4.4",
            question: "I have another type of disability and/or impairment, please state it",
            type: "text",
            skipable:true
        
          }
        ]
      },
            ]



module.exports = questions