import React, { useState , useEffect} from "react";
import Typewriter from 'typewriter-effect'; // Import Typewriter.js
import { FaCheckCircle, FaUser, FaExclamationCircle } from 'react-icons/fa';
import { motion } from "framer-motion"; // For animations
import { FaPaperPlane, FaPlus, FaChevronDown, FaRegComment } from 'react-icons/fa';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import Spinner from "./spinner";

const Dashboard = () => {
  const [inputValue, setInputValue] = useState("");
  const [messages, setMessages] = useState([]);
  const [isTypingComplete, setIsTypingComplete] = useState(false); // To track when the typewriter effect finishes
  const [isPaneOpen, setPaneOpen] = useState(false); 
  const [isHovered, setHovered] = useState(false); 
  const [userId, setUserId] = useState(null); 
  const [recentChats, setRecentChats] = useState([]);
  const [info , setInfo] = useState(null)
  const [loading , setLoading] = useState(false)
  const [name, setName] = useState(''); // New state for hover()
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    const storedId = sessionStorage.getItem('userId');
    if (storedId) {
      const cleanedId = storedId.replace(/['",]/g, '');
      setUserId(cleanedId);
    }
  
    if (userId) {
      const fetchRecentChats = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/recent/chat/${userId}`);
          if (!response.ok) {
            throw new Error('Failed to fetch recent chats');
          }
  
          const data = await response.json();
          setRecentChats(data);
        } catch (error) {
          console.error('Error fetching recent chats:', error);
        }
      };
  
      const fetchInfo = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/user/${userId}`);
          if (!response.ok) {
            throw new Error('Failed to fetch user info');
          }
  
          const data = await response.json();
          console.log(data);
          setInfo(data);
        } catch (error) {
          console.error('Error fetching user info:', error);
        }
      };
  
      fetchRecentChats();
      fetchInfo();
    }
  }, [userId]);


  useEffect(() => {
    // Retrieve the userId from sessionStorage
    const storedName = sessionStorage.getItem('name');
    if (storedName) {
      const cleaneduser = storedName.replace(/['",]/g, '')
      setName(cleaneduser);
    
    } else {
      console.log('No userId found in sessionStorage.');
    }
  }, []);



  
  const options = [
    {
      text: 'Check Filing Status',
      icon: <FaCheckCircle size={15} />,
      onClick: () => handleCheckFilingStatus(),
    },
    {
      text: 'Talk to Case Manager',
      icon: <FaUser size={15} />,
      onClick: () => handleTalkToCaseManager(),
    },
    {
      text: 'Submit a Complaint',
      icon: <FaExclamationCircle size={15} />,
      onClick: () => handleSubmitComplaint(),
    },
  ];

  // Handler for "Check Filing Status"
  const handleCheckFilingStatus = async () => {
    try {
      // Display loading indicator (if necessary)
      console.log('Checking filing status...');
      const cleaneduserId = userId.replace(/['",]/g, '')
      console.log(userId)
      // Make an asynchronous API call using fetch
      const response = await fetch(`https://api.migrantgenie.com/api/v1/file/${cleaneduserId}`, {
        method: 'GET', // or 'GET', depending on the API
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      // Check if the response is OK
      if (!response.ok) {
        throw new Error('Failed to check filing status');
      }
  
      // Parse the response data
      const data = await response.json();

      navigate('/filling', { state: { advice: data.message } });
      
      // Handle the response data (e.g., update state, display in UI)
      console.log('Filing status response:', data);
  
      // Call the function to update the state or UI with the filing status
      // updateFilingStatus(data.status);
    } catch (error) {
      // Handle errors
      console.error('Error checking filing status:', error.message);
    } finally {
      // Hide loading indicator (if necessary)
      console.log('Check filing status complete');
    }
  };
  
  // Handler for "Talk to Case Manager"
  const handleTalkToCaseManager = () => {
    const calendlyUrl = 'https://calendly.com/your-calendly-link'; // Replace with your Calendly link
    window.open(calendlyUrl, '_blank'); // Open Calendly in a new tab
  };
  
  // Handler for "Submit a Complaint"
  const handleSubmitComplaint = () => {
    navigate('/complaint'); // Navigate to complaint page
  };


  // Handle sending message
  const handleSendMessage = (e) => {
    e.preventDefault();
    if (inputValue.trim()) {
      // Add user's complaint message to the chat
      setMessages((prevMessages) => [...prevMessages, { text: inputValue, sender: 'user' }]);

      // Send the complaint to the backend
      sendComplaintToBackend(inputValue);

      setInputValue(""); // Clear input field
    }
  };

  const sendComplaintToBackend = async () => {
    setLoading(true)
    // Simulate sending data to backend (replace with actual API call)
    const cleaneduserId = userId.replace(/['",]/g, '')
    console.log(userId)

    try {
      const response = await fetch(`https://api.migrantgenie.com/api/v1/complain/${cleaneduserId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ complain:inputValue }),
      });

      if (response.ok) {
        const data = await response.json()
        console.log({data})
        setLoading(false)
        setShowSuccessModal(true)

      } else {
        setLoading(false)
        alert('Failed to submit complaint.');
      }
    } catch (error) {
      console.error('Error submitting complaint:', error);
      setLoading(false)
      alert('Error submitting complaint.');
    }
  };

  
  const closeModalAndNavigate = () => {
    setShowSuccessModal(false);
    navigate('/chat'); // Navigate to the chat page
  };
  return (
    <div className={`flex justify-between min-h-screen h-screen bg-[#2a2a2a] text-white transition-all`}>
        { loading && <Spinner/>}
           <motion.div 
        className={` hidden bg-[#2a2a2a] text-white h-screen w-16 p-4 lg:flex flex-col justify-between transition-all duration-300 ${isPaneOpen ? 'w-64 rounded-lg mt-1 mx-3' : 'w-16'}  ${isHovered ? 'bg-[#444444]' : '#2a2a2a'}`}
        initial={false}
        animate={{ width: isPaneOpen ? "18rem" : "17rem" }}
        onMouseEnter={() => {
          setPaneOpen(true);
          setHovered(true);
        }} 
        onMouseLeave={() => {
          setPaneOpen(false);
          setHovered(false);
        }}

      >
        {/* Top Section */}
        <div className="space-y-4">
          {/* Logo or Header */}
          <div className="flex justify-start">
          <Link to='/dashboard'>
            <img  src="/LogoForMG.png" alt="Logo" className="w-10 h-10 rounded-full" />
            </Link>
          </div>

          {/* Start new chat */}
          {isPaneOpen && (
            <button className="bg-black w-full text-left p-3 flex items-center justify-between rounded-md hover:bg-gray-800">
              <span className="font-semibold">Start new chat</span>
              <FaPlus />
            </button>
          )}

          {/* Starred Chats */}
        

          {/* Recent Chats */}
          {isPaneOpen && (
            <div className="mt-6">
              <h2 className="text-gray-400 mb-2 text-sm font-bold">Recents</h2>
              {recentChats.length === 0 ? (
          <div className="col-span-1 md:col-span-2 lg:col-span-3 text-center text-gray-500">
            No recent chats
          </div>
        ) : (
          // Chat Items
          recentChats.map((chat) => (
            <motion.div
              key={chat._id}
              whileHover={{ scale: 1.05 }}
              className="bg-gray-800 dark:bg-[#383838] bg-opacity-90 p-4 rounded-lg shadow-md flex justify-between items-center transition-transform duration-300 mt-3"
            >
              <div>
                <p className="text-sm text-gray-400">{chat.chatName}</p>
              
              </div>
              <button
                className="text-blue-500 dark:text-blue-300 text-sm hover:underline hover:text-blue-600 dark:hover:text-blue-400"
                onClick={() => {
                  // Handle view chat action
                  navigate('/chat', { state: {  chatId : chat._id } });
                }}
              >
                View
              </button>
            </motion.div>
          ))
        )}
            </div>
          )}
        </div>

        {/* Bottom Section - Profile */}
        <div className="flex items-center justify-start p-4">
          <div className="bg-gray-700 p-2 w-[40px] h-[40px] text-center rounded-full">
            <span className="text-white text-sm font-bold">{name.charAt(0)}</span>
          </div>
          {isPaneOpen && (
            <div className="ml-2">
              <div className="font-bold">{name}</div>
              {info ? (
      
       <div className="text-gray-400 text-sm">{info.subscriptionPlan}</div>
    ) : (
      "Loading user info..."
    )}
              
            </div>
          )}
        </div>
      </motion.div>
      {/* Middle Pane (Chat Section) */}
      <div className="w-6/6 lg:w-3/6 flex flex-col justify-between p-6 mt-3 rounded">
        {/* Display messages */}
        <div className="flex-grow overflow-y-auto">
          {messages.map((message, index) => (
            <div key={index} className={`mb-2 flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
              <div className={`p-2 rounded-lg shadow-lg ${message.sender === 'user' ? 'bg-[#1f1f1f] text-white' : 'bg-[#4d4d4d] text-white'}`}>
                <span className="text-sm">{message.text}</span>
              </div>
            </div>
          ))}

          {/* Typewriter message */}
          {!isTypingComplete && (
            <div className="mb-2 flex justify-start">
              <div className="p-2 rounded-lg shadow-lg bg-[#4d4d4d] text-white">
              <Typewriter
      onInit={(typewriter) => {
        typewriter
          .typeString("Sorry, you have to use this feature. What is your complaint?")
          .pauseFor(500)
          .callFunction(() => {
            // Ensure to set messages only after typewriter finishes
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: "Sorry, you have to use this feature. What is your complaint?", sender: 'Claude' }
            ]);
            setIsTypingComplete(true); // Enable input field after typing is complete
          })
          .start();
      }}
    />
              </div>
            </div>
          )}
        </div>

        {/* Chat Input */}
        <div className="mt-4 mb-6">
          <div className="flex items-center bg-gray-800 rounded-lg">
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Type your complaint"
              className="flex-1 p-3 bg-gray-700 dark:bg-gray-800 rounded-lg focus:outline-none"
              disabled={!isTypingComplete} // Disable input while typing effect is in progress
            />
            <button type="submit" onClick={handleSendMessage} className="ml-2 hover:text-blue-600 px-4" disabled={!isTypingComplete}>
              <FaPaperPlane size={24} />
            </button>
          </div>
        </div>
      </div>

      <div className="hidden lg:block w-1/6 p-4 bg-[#2a2a2a] mt-3">
        <div>
          <p className="mb-4 font-bold">Other Activities</p>
          <p className="text-gray-400 text-[13px]">MigrantGenie AI</p>
          <p className="text-gray-400 text-[13px]">Immigration AI</p>
        </div>

      

           <div>
      {/* <p className="mt-4 text-[10px]">Artifacts</p> */}
      {
          info?.subscribed && (
            <div>
            <p className="mt-4 text-[10px]">Options</p>
            {options.map((option, index) => (
              <div key={index}
                className="flex rounded-lg p-1 mt-2 bg-[#333333] shadow-lg cursor-pointer"
                onClick={option.onClick} // Attach the onClick handler
              >
                <div className="flex justify-center items-center bg-[#1f1f1f] px-4 py-2">
                  {option.icon}
                </div>
                <div className="flex items-center">
                  <p className="text-[12px] pl-2 border-l">{option.text}</p>
                </div>
              </div>
            ))}
          </div>
          )
        }
    </div>

      </div>
      {showSuccessModal && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center text-[black] justify-center">
          <div className="bg-white p-6 rounded-lg text-center">
            <h2 className="text-2xl font-bold mb-4"> Recieved</h2>
            <p>Your Complaint has been registered and  a response will be sent to mail</p>
            <button
              onClick={closeModalAndNavigate} // Close the modal and navigate to chat page
              className="bg-blue-500 text-white px-4 py-2 mt-4 rounded hover:bg-blue-600"
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
