const questions = [
    {
      Part: 1,
      partIntro: 'Welcome',
      questionfor: [
        {
          id: "1.1",
          question: "Reason for Filing",
          type: "single_select",
          required: true,
          options: [
            {
              id: "A",
              value: "General Provision",
              helper_text: "See Instructions: List of General Eligibility Requirements"
            },
            {
              id: "B",
              value: "Spouse of U.S. Citizen",
              helper_text: "See Instructions: Eligibility Based on Marriage to a U.S. Citizen"
            },
            {
              id: "C",
              value: "VAWA",
              helper_text: "See Instructions: Eligibility for the Spouse, Former Spouse, or Child of a U.S. Citizen under VAWA"
            },
            {
              id: "D",
              value: "Spouse of U.S. Citizen in Qualified Employment Outside the United States",
              helper_text: "See Instructions: Eligibility for the Spouse of a U.S. Citizen Working for a Qualified Employer"
            }
          ]
        },
        {
            id: "1.2",
            question: "Your Current Legal Name",
            helper_text: "Do not provide a nickname",
            type: "text_fields",
            subfields: [
              {
                field: "Family Name (Last Name)",
                required: true
              },
              {
                field: "Given Name (First Name)",
                required: true
              },
              {
                field: "Middle Name",
                required: false
              }
            ]
          },
        {
            id: "1.3",
            question: "Other Names Used Since Birth",
            type: "text_fields",
            skipable:true,
            helper_text: "Include maiden name, nicknames, and aliases",
            subfields: [
              {
              
                field: "Family Name (Last Name)",
                required: false
              },
              {
                
                field: "Given Name (First Name)",
                required: false
              },
              {
              
                field: "Middle Name",
                required: false
              }
            ]
          },
          {
            id: "1.4",
            question: "Do you want to change your name, if yes , Enter New Name or Skip the Question",
            helper_text: "Do not provide a nickname",
            skipable:true,
            type: "text_fields",
            subfields: [
              {
                field: "Family Name (Last Name)",
                required: true
              },
              {
                field: "Given Name (First Name)",
                required: true
              },
              {
                field: "Middle Name",
                required: false
              }
            ]
          },
          {
            id: "1.5",
            question: "USCIS Online Account Number",
            required: false,
            helper_text : "If any",
            skipable:true
          },
          {
            id: "1.6",
            question: "Gender",
            type: "single_select",
            required: true,
            options: [
                {
                  id: "A",
                  value: "Male",
                  
                },
                {
                  id: "B",
                  value: "Female",
                
                },
                {
                  id: "C",
                  value: "Other Gender Indentity",
                  
                },
               
              ]
           
          },
          {
            id: "1.7",
            question: "Date of Birth",
            type: "date",
            format: "mm/dd/yyyy",
            required: true
          },
          {
            id: "1.8",
            question: "Date you became a lawful permanent resident",
            type: "date",
            format: "mm/dd/yyyy",
            required: true,
          },
          {
            id: "1.9",
            question: "Country of Birth",
            type: "text",
            required: true
          },
          {
            id: "1.10",
            question: "Country of Citizenship or Nationality",
            type: "text",
            required: true,
            
          },
          {
            id: "1.11",
            question: "Was one of your parents (including adoptive parents) a U.S. citizen before your 18th birthday?",
            type: "yes_no",
          },
          {
            id: "1.12",
            question: "Do you have a physical or developmental disability or mental impairment that prevents you from demonstrating your knowledge and understanding of the English language or civics requirements for naturalization?",
            type: "yes_no",
            required: true
          },
          {
            id: "1.13",
            question: "Do you want the Social Security Administration (SSA) to issue you an original or replacement Social Security card and update your immigration status with the SSA if and when you are naturalized?",
            type: "yes_no"
          },
          {
            id: "1.14",
            question: "Social Security Number",
            type: "number",
            format: "###-##-####",
            required: false,
            skipable:true,
          },
      
      
     
      ]
    },
    {
      Part: 2,
      partIntro: 'Biographic Information',
      questionfor: [
        {
          id: "2.1",
          question: "Ethnicity",
          type: "single_select",
          required: true,
          options: [
            {
              id: "A",
              value: "Hispanic or Latino",
              
            },
            {
              id: "B",
              value: "Not Hispanic or Latino",
            
            }
           
          ]
        },
        {
          id: "2.2",
          question: "Race",
          type: "single_select",
          required: true,
          options: [
            {
              id: "A",
              value: "White",
              
            },
            {
              id: "B",
              value: "Asian",
            
            },
            {
                id: "C",
                value: "Black or African American",
              
              },
              {
                id: "D",
                value: "American Indian or Alaska Native",
              
              },
              {
                id: "E",
                value: "Native Hawaiian or Other Pacific Islander",
              
              },
           
          ]
        },
        {
          id: "2.3",
          question: "Height",
          type: "text_fields",
          required: true,
          subfields: [
            {
              field: "Feet",
              type: "number"
            },
            {
              field: "Inches",
              type: "number"
            }
          ]
        },
        {
          id: "2.4",
          question: "Weight",
          type: "number",
          unit: "Pounds",
          required: true
        },
        {
          id: "2.5",
          question: "Eye color",
          type: "single_select",
          required: true,
          options: [
            
            {
                id: "A",
                value: "Black",
              
              },
              {
                id: "B",
                value: "Blue",
              
              },
              {
                id: "C",
                value: "Brown",
              
              },
              {
                id: "D",
                value: "Gray",
              
              },
              {
                id: "E",
                value: "Green",
              
              },
              {
                id: "F",
                value: "Hazel",
              
              },
              {
                id: "G",
                value: "Maroon",
              
              },
              {
                id: "H",
                value: "Pink",
              
              },
              {
                id: "I",
                value: "Unknown / Other",
              
              },
          ]
        },
        {
          id: "2.6",
          question: "Hair color",
          type: "single_select",
          required: true,
          
            
            options: [
            
                {
                    id: "A",
                    value: "Black",
                  
                  },
                  {
                    id: "B",
                    value: "Bald (No Hair)",
                  
                  },
                  {
                    id: "C",
                    value: "Brown",
                  
                  },
                  {
                    id: "D",
                    value: "Gray",
                  
                  },
                  {
                    id: "E",
                    value: "Blond",
                  
                  },
                  {
                    id: "F",
                    value: "Red",
                  
                  },
                  {
                    id: "G",
                    value: "Sandy",
                  
                  },
                  {
                    id: "H",
                    value: "White",
                  
                  },
                  {
                    id: "I",
                    value: "Unknown / Other",
                  
                  },
              ]
          
        }
      ]
    },
    {
        Part: 3,
        partIntro: "Information About Your Residence",
        questionfor: [
          {
            id: "3.1",
            question: "Physical Addresses",
            type: "text_fields",
            required: true,
            helper_text: "List every location where you have lived during the last 5 years",
            subfields: [
              {
                field: "Street Number and Name",
                required: true
              },
              {
                field: "Apt/Ste/Flr Number",
                required: false
              },
              {
                field: "City or Town",
                required: true
              },
              {
                field: "State/Province",
                required: true
              },
              {
                field: "ZIP Code/Postal Code",
                required: true
              },
              {
                field: "Country",
                required: true
              },
              {
                field: "Dates of Residence From",
                type: "date",
                required: true
              },
              {
                field: "Dates of Residence To",
                type: "date",
                required: true
              }
            ]
          },
        
          {
            id: "3.2",
            question: "Current Mailing Address",
            type: "text_fields",
            required: true,
            subfields: [
              {
                field: "In Care Of Name",
                required: false
              },
              {
                field: "Street Number and Name",
                required: true
              },
              {
                field: "Apt/Ste/Flr Number",
                required: false
              },
              {
                field: "City or Town",
                required: true
              },
              {
                field: "State/Province",
                required: true
              },
              {
                field: "ZIP Code/Postal Code",
                required: true
              },
              {
                field: "Country",
                required: true
              }
            ]
          }
        ]
    },
    {
        Part: 4,
        partIntro: "Information About Your Marital History",
        questionfor: [
          {
            id: "4.1",
            question: "What is your current marital status?",
            type: "single_select",
            required: true,
           
              
              options: [
            
                {
                    id: "A",
                    value: "Single , Never Married",
                  
                  },
                  {
                    id: "B",
                    value: "Married",
                  
                  },
                  {
                    id: "C",
                    value: "Divorced",
                  
                  },
                  {
                    id: "D",
                    value: "Widowed",
                  
                  },
                  {
                    id: "E",
                    value: "Marriage Annulled",
                  
                  },
                  {
                    id: "F",
                    value: "Seperated",
                  
                  }
                 
                  
                  
              ]
            
          },
          {
            id: "4.2",
            question: "If you are currently married, is your spouse a current member of the U.S. armed forces?",
            type: "yes_no",
            skipable:true
          },
          {
            id: "4.3",
            question: "How many times have you been married?",
            type: "number",
            required: true,
          },
          {
            id: "4.4",
            question: "Your Current Partner's Legal Name",
            helper_text: "Do not provide a nickname",
            skipable:true,
            type: "text_fields",
            subfields: [
              {
                field: "Family Name (Last Name)",
                required: true
              },
              {
                field: "Given Name (First Name)",
                required: true
              },
              {
                field: "Middle Name",
                required: false
              },
              {
           field: "Current Spouse's Date of Birth",
              required: true,
              },
              {
                field: "Date You Entered into Marriage with Current Spouse",
              type: "date",
              required: true
              },
            {
                field: "Physical Address",
                type: "date",
                required: true
            }
              
            ]
          },
             
            ]
    },
    {
        Part: 5,
        partIntro: "Information About Your Children",
        questionfor: [
          {
            id: "5.1",
            question: "Indicate your total number of children under 18 years of age",
            type: "number",
            required: true
          },
          {
            id: "5.2",
            question: "Child Information",
            type: "text_fields",
            skipable:true,
            subfields: [
              {
                field: "Child's Name (First Name and Family Name)",
                required: true
              },
              {
                field: "Date of Birth",
                required: true
              },
              {
               
              },
             
            ]
          },
          {
            id: "5.3",
            field: "Relationship",
            type: "single_select",
            required: true,
            skipable:true,
    
              
              options: [
            
                {
                    id: "A",
                    value: "Biological child",
                  
                  },
                  {
                    id: "B",
                    value: "Step Child",
                  
                  },
                  {
                    id: "C",
                    value: "Legally Adopted Child",
                  
                  },
                  {
                    id: "D",
                    value: "Gray",
                  
                  },
                  {
                    id: "E",
                    value: "Green",
                  
                  },
                  {
                    id: "F",
                    value: "Hazel",
                  
                  },
                  {
                    id: "G",
                    value: "Maroon",
                  
                  },
                  {
                    id: "H",
                    value: "Pink",
                  
                  },
                  {
                    id: "I",
                    value: "Unknown / Other",
                  
                  },
              ]
            
          },
          {
            id: "5.4",
            field: "Are you providing support for this child?",
            type: "yes_no",
            skipable:true
          }
        ]
      
    },

    {
        Part: 6,
        partIntro: "Information About Your Employment and Schools You Attended",
        questionfor: [
          {
            id: "6.1",
            question: "List where you have worked or attended school full time or part time during the last 5 years",
            type: "text_fields",
            subfields: [
              {
                field: "Employer or School Name",
                required: true
              },
              {
                field: "City/Town",
                required: true
              },
              {
                field: "State/Province",
                required: true
              },
              {
                field: "ZIP Code/Postal Code",
                required: true
              },
              {
                field: "Country",
                required: true
              },
              {
                field: "Occupation or Field of Study",
                required: true
              },
              {
                field: "Dates From",
                type: "date",
                required: true
              },
              {
                field: "Dates To",
                type: "date",
                required: true
              }
            ]
          }
        ]
    },
    {
        Part: 7,
        partIntro: "Time Outside the United States",
        questionfor: [
          {
            id: "7.1",
            type: "text_fields",
            skipable:true,
            question: "List all trips outside the United States that lasted more than 24 hours during the last 5 years",
            required: true,
            subfields: [
              {
                field: "Date You Left the United States",
                type: "date",
                required: true
              },
              {
                field: "Date You Returned to the United States",
                type: "date",
                required: true
              },
              {
                field: "Countries to Which You Traveled",
                type: "text",
                required: true
              }
            ]
          }
        ]
      },
      {
        Part: 9,
        partIntro: "Additional Information About You",
        description: "Answer all questions. If you answer 'Yes' to any question, include an explanation in Part 14.",
        questionfor: [
          {
            id: "8.1",
            question: "Have you EVER claimed to be a U.S. citizen (in writing or any other way)?",
            type: "yes_no",
            required: true
          },
          {
            id: "8.2",
            question: "Have you EVER registered to vote or voted in any Federal, state, or local election in the United States?",
            type: "yes_no",
            required: true,
            helper_text: "If you lawfully voted only in a local election where noncitizens are eligible to vote, you may answer 'No'"
          },
          {
            id: "8.3",
            question: "Do you currently owe any overdue Federal, state, or local taxes in the United States?",
            type: "yes_no",
            required: true
          },
          {
            id: "8.4",
            question: "Since you became a lawful permanent resident, have you called yourself a 'nonresident alien' on a Federal, state, or local tax return or decided not to file a tax return because you considered yourself to be a nonresident?",
            type: "yes_no",
            required: true
          },
          {
            id: "8.5",
            question: "Have you EVER been a member of, involved in, or in any way associated with any Communist or totalitarian party anywhere in the world?",
            type: "yes_no",
            required: true
          }
        ]
      },
      {
        Part: 9,
        partIntro: "Request for a Fee Reduction",
        questionfor: [
          {
            id: "10.1",
            question: "My household income is less than or equal to 400% of the Federal Poverty Guidelines",
            type: "yes_no",
            required: true
          },
          {
            id: "10.2",
            question: "Total household income",
            type: "currency",
            required: true,
           
          },
          {
            id: "10.3",
            question: "My household size is",
            type: "number",
            required: true,
          },
          {
            id: "10.4",
            question: "Total number of household members earning income including yourself",
            type: "number",
            required: true
          },
          {
            id: "10.5",
            question: "I am the head of household",
            type: "yes_no",
            required: true,
          }
        ]
      },
      {
        Part: 10,
        partIntro: "Applicant's Contact Information, Certification, and Signature",
        questionfor: [
          {
            id: "10.1",
            question: "Applicant's Daytime Telephone Number",
            type: "phone",
            required: true
          },
          {
            id: "10.2",
            question: "Applicant's Mobile Telephone Number",
            type: "phone",
            required: false
          },
          {
            id: "11.3",
            question: "Applicant's Email Address",
            type: "email",
            required: false
          }
        
        ]
      },

      {
        Part: 11,
        partIntro: "Supporting Documents",
        questionfor: [
          {
            id: "11.1",
            question: "Upload Birth Certificate",
            type: "image_upload",
            required: true
          },
          {
            id: "11.2",
            question: "Upload Permanent Resident Card",
            type: "image_upload",
            required: true
          },
          {
            id: "11.3",
            question: "Upload Marriage Certificate",
            skipable:true,
            type: "image_upload",
            required: false
          },
          {
            id: "11.4",
            question: "Upload Divorce or Death Certificate",
            skipable:true,
            type: "image_upload",
            required: false
          },
        
        ]
      },
        
]
      
  


  module.exports = questions