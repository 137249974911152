import React from "react";

const PricingPage = () => {
  return (
    <div className="min-h-screen bg-[#f7f3eb] flex flex-col items-center p-6 font-sans">
      {/* Page Title */}
      <h1 className="text-4xl font-bold mt-6 text-gray-800">Choose your plan</h1>
      <p className="mt-4 text-gray-600">
        You must have a work domain to select the Team plan.
      </p>

      {/* Plans Section */}
      <div className="flex flex-col md:flex-row justify-center mt-10 gap-8">
        {/* Pro Plan */}
        <div className="bg-white rounded-xl shadow-lg p-6 w-full md:w-1/3 text-center border border-gray-200">
          <div className="flex justify-center mb-6">
            <img
              src="https://via.placeholder.com/50x50" // Replace with the actual icon/image URL
              alt="Pro Icon"
              className="w-12 h-12"
            />
          </div>
          <h2 className="text-2xl font-bold text-gray-800">Pro</h2>
          <p className="mt-2 text-lg font-semibold text-gray-700">US$20 / month</p>
          <ul className="mt-4 space-y-3 text-gray-600 text-sm">
            <li>Level up your Claude usage with 5x more usage versus Free plan</li>
            <li>Access to Claude 3 Haiku, our fastest model, and Claude 3 Opus</li>
            <li>Create Projects to work with Claude around a set of docs, code, or files</li>
            <li>Priority access during high-traffic periods</li>
            <li>Early access to new features</li>
          </ul>
          <button className="mt-6 w-full bg-[#face4d] text-white py-2 rounded-md hover:bg-[#f9d542] transition-all">
            Get Started
          </button>
        </div>

        {/* Team Plan */}
        <div className="bg-white rounded-xl shadow-lg p-6 w-full md:w-1/3 text-center border border-gray-200">
          <div className="flex justify-center mb-6">
            <img
              src="https://via.placeholder.com/50x50" // Replace with the actual icon/image URL
              alt="Team Icon"
              className="w-12 h-12"
            />
          </div>
          <h2 className="text-2xl font-bold text-gray-800">Team</h2>
          <p className="mt-2 text-lg font-semibold text-gray-700">
            US$25 / month*
            <br />
            <span className="text-sm text-gray-500">Per member, 5 minimum</span>
          </p>
          <ul className="mt-4 space-y-3 text-gray-600 text-sm">
            <li>Everything in Pro</li>
            <li>Higher usage limits versus Pro plan</li>
            <li>Share and discover chats from teammates</li>
            <li>Central billing and administration</li>
          </ul>
          <button className="mt-6 w-full bg-[#face4d] text-white py-2 rounded-md hover:bg-[#f9d542] transition-all">
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
