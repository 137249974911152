import React, { useEffect, useState, useRef } from "react";
import { FileIcon, ImageIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { FaPaperPlane, FaPlus } from "react-icons/fa";
import { motion } from "framer-motion"; // For animations
import { useForm } from "react-hook-form";
import { FaCheckCircle, FaUser, FaExclamationCircle } from 'react-icons/fa';
import Spinner from './spinner';

const FilePreviewMessage = ({ file }) => (
  <div className="flex items-center gap-2 p-2 bg-gray-800 rounded-lg">
    <div className="flex-shrink-0">
      {file.type?.startsWith('image/') ? (
        <ImageIcon className="w-10 h-10 text-gray-400" />
      ) : (
        <FileIcon className="w-10 h-10 text-gray-400" />
      )}
    </div>
    <div className="flex-1 min-w-0">
      <p className="text-sm text-gray-200 truncate">{file.name}</p>
      <p className="text-xs text-gray-400">{(file.size / 1024 / 1024).toFixed(2)} MB</p>
    </div>
  </div>
);

const Dashboard = () => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [isTyping, setIsTyping] = useState(false);
  const [loading , setLoading] = useState(false)
  const [isPaneOpen, setPaneOpen] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const [recentChats, setRecentChats] = useState([]);
  const [userId, setUserId] = useState('')
  const [name, setName] = useState('');
  const [info , setInfo] = useState('')
  const chatEndRef = useRef(null);
  

  const questions = [
    "You made it to this page, we are so excited to begin your journey, Enter START to begin your application",
    "What is your last name?",
    "What is your first name?",
    "What is your middle name ?",
    "what is your sex ?",
    "Input email address ?",
    "Input fax number",
    "Input phone numbers",
    "Input House Address",
    "Input Date of Birth",
    "Input Place of Birth",
    "Input U.S Social Security Number if any else type NONE",
    "Input Alien Registration Number if any else type NONE",
    "What is your marital status",
    "What is your Ethnicity",
    "What is your Race",
    "What is your Height",
    "What is your Weight",
    "What is your Eye Color",
    "What is your Hair Color",
    "What is Your Father's last name",
    "What is your Father's first name",
    "What is your Father's middle name",
    "Input Father's Date of Birth",
    "Input Father's town of Birth",
    "Input Father's current city",
    "Input Father's country of residence",
    "What is Your Mother's last name",
    "What is your Mother's first name",
    "What is your Mother's middle name",
    "Input Mother's Date of Birth",
    "Input Mother's town of Birth",
    "Input Mothers's current city",
    "Input Mothers's country of residence",
    "Name of spouse",
    "Spouse's Date of Birth",
    "Spouse's City/Town of Birth",
    "Date and City/Town of Marriage",
    "Full Name of former spouse if applicable",
    "Former spouse's Date of Birth",
    "Former spouse's City/Town of Birth",
    "Date and City/Town of Marriage",
    "Date and City/Town of Termination",
    "Current Address in reverse chronological order",
    "Previous Address in reverse chronological order",
    "Employer names if applicable",
    "Work Address in reverse chronological order",
    "Job Title if applicable",
    "Dates of employment",
    `Hey!!! well done, you just finished the personal information catgory now we are entering the petitioner category get ready, you can grab yourself a drink, I know it's a lot of questions we are asking. Enter PROCEED to continue`,
    "Do you plan to sponsor any other family members within the next 5 months?",
    "Are you currently on Active Duty in the U.S. Military?",
    "Number of dependent children",
    "Have you previously sponsored others who are now lawful permanent residents?",
    "Do you have other family members in your household whose income you are combining with yours?",
    "Current Individual Annual Income (pre-tax)",
    "If applicable: Other Household Member(s) Income (name, relationship, income)",
    "Total Household Income",
    "Is all your income shown on W-2 forms? (Yes/No)",
    "Have you filed a federal tax return for each of the last three years?",
    "If yes, list your total adjusted gross income for each of the past three years",
    "Do you have IRS Form 1040 or Transcripts for these years",
    "Upload passport photos on a white Background.",
    "Upload Biodata Page of U.S Passport",
    "Upload Birth Certificate or Certificate of Non-Availability of Birth Certificate"
  ];

  const options = [
    {
      text: 'Check Filing Status',
      icon: <FaCheckCircle size={15} />,
      onClick: () => handleCheckFilingStatus(),
    },
    {
      text: 'Talk to Case Manager',
      icon: <FaUser size={15} />,
      onClick: () => handleTalkToCaseManager(),
    },
    {
      text: 'Submit a Complaint',
      icon: <FaExclamationCircle size={15} />,
      onClick: () => handleSubmitComplaint(),
    },
  ];

  useEffect(() => {
    // Retrieve the userId from sessionStorage
    const storedName = sessionStorage.getItem('name');
    const storedUserId = sessionStorage.getItem('userId');
    setUserId(storedUserId);
    console.log({storedName})
    if (storedName) {
      // If a userId is found, update the state
      const cleaneduser = storedName.replace(/['",]/g, '')
      setName(cleaneduser);
    
    } else {
      console.log('No userId found in sessionStorage.');
    }
  }, []);
  
  const handleCheckFilingStatus = async () => {
    try {
      
      const cleaneduserId = userId.replace(/['",]/g, '')
      console.log(userId)
      // Make an asynchronous API call using fetch
      const response = await fetch(`https://api.migrantgenie.com/api/v1/file/${cleaneduserId}`, {
        method: 'GET', // or 'GET', depending on the API
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      // Check if the response is OK
      if (!response.ok) {
        throw new Error('Failed to check filing status');
      }
  
      // Parse the response data
      const data = await response.json();

      navigate('/filling', { state: { advice: data.message } });
      
      // Handle the response data (e.g., update state, display in UI)
      console.log('Filing status response:', data);
  
      // Call the function to update the state or UI with the filing status
      // updateFilingStatus(data.status);
    } catch (error) {
      // Handle errors
      console.error('Error checking filing status:', error.message);
    } finally {
      // Hide loading indicator (if necessary)
      console.log('Check filing status complete');
    }
  };
  

  // Handler for "Talk to Case Manager"
  const handleTalkToCaseManager = () => {
    const calendlyUrl = 'https://calendly.com/your-calendly-link'; // Replace with your Calendly link
    window.open(calendlyUrl, '_blank'); // Open Calendly in a new tab
  };

  // Handler for "Submit a Complaint"
  const handleSubmitComplaint = () => {
    navigate('/complaint'); // Navigate to complaint page
  };

  const serializeFile = (file) => {
    if (!file) return null;
    return {
      name: file.name,
      size: file.size,
      type: file.type,
      lastModified: file.lastModified,
      isFile: true
    };
  };

  const saveFormState = () => {
    const formState = {
      answers,
      currentQuestionIndex,
      messages
    };
    localStorage.setItem('formState', JSON.stringify(formState));
  };

  useEffect(() => {
    const savedStateStr = localStorage.getItem('formState');
    if (savedStateStr) {
      try {
        const savedState = JSON.parse(savedStateStr);
        
        if (savedState.answers) {
          setAnswers(savedState.answers);
        }
        
        if (typeof savedState.currentQuestionIndex === 'number') {
          setCurrentQuestionIndex(savedState.currentQuestionIndex);
        }
        
        if (Array.isArray(savedState.messages)) {
          setMessages(savedState.messages);
        } else {
          setMessages([{ text: questions[0], sender: "Claude" }]);
        }
      } catch (error) {
        console.error("Error loading saved state:", error);
        setMessages([{ text: questions[0], sender: "Claude" }]);
      }
    } else {
      setMessages([{ text: questions[0], sender: "Claude" }]);
    }
  }, []);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleTextInput = (e) => {
    const newAnswers = { ...answers, [`question${currentQuestionIndex}`]: e.target.value };
    setAnswers(newAnswers);
    localStorage.setItem('formState', JSON.stringify({
      answers: newAnswers,
      currentQuestionIndex,
      messages
    }));
  };

  const newPage = ()=>{
    setLoading(true)
    navigate('/chat/c')
    setLoading(false)
  }

  useEffect(() => {
    const storedId = sessionStorage.getItem('userId');
    if (storedId) {
      const cleanedId = storedId.replace(/['",]/g, '');
      setUserId(cleanedId);
    }
  
    if (userId) {
      const fetchRecentChats = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/recent/chat/${userId}`);
          if (!response.ok) {
            throw new Error('Failed to fetch recent chats');
          }
  
          const data = await response.json();
          setRecentChats(data);
        } catch (error) {
          console.error('Error fetching recent chats:', error);
        }
      };
  
      const fetchInfo = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/user/${userId}`);
          if (!response.ok) {
            throw new Error('Failed to fetch user info');
          }
  
          const data = await response.json();
          console.log(data);
          setInfo(data);
        } catch (error) {
          console.error('Error fetching user info:', error);
        }
      };
  
      fetchRecentChats();
      fetchInfo();
    }
  }, [userId]);

  // const handleFileUpload = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const serializedFile = serializeFile(file);
  //     const newAnswers = { ...answers, [`question${currentQuestionIndex}`]: serializedFile };
  //     setAnswers(newAnswers);
  //     localStorage.setItem('formState', JSON.stringify({
  //       answers: newAnswers,
  //       currentQuestionIndex,
  //       messages
  //     }));
  //   }
  // };

  const validateCurrentAnswer = () => {
    const currentAnswer = answers[`question${currentQuestionIndex}`];
    const isUploadQuestion = questions[currentQuestionIndex].toLowerCase().includes("upload");
    if ((isUploadQuestion && !currentAnswer?.name) || (!isUploadQuestion && !currentAnswer)) {
      setMessages(prev => [...prev, { text: "Please provide an answer before proceeding.", sender: "Claude" }]);
      return false;
    }
    return true;
  };

  const sendToBackend = async (questionIndex1, questionIndex2) => {
    const formData = new FormData();

    const questionsWithAnswers = [questionIndex1, questionIndex2].map(index => {
      const question = questions[index];
      const answer = answers[`question${index}`];
      // Only include the answer text for non-file questions
      if (!question.toLowerCase().includes("upload")) {
        return { question, answer };
      }
      // For file questions, we'll handle the file separately
      return { question, answer: `File: ${answer.name}` };
    });

    // Append the questions data
    formData.append('questions', JSON.stringify(questionsWithAnswers));

    // Append the actual files
    [questionIndex1, questionIndex2].forEach((index, arrayIndex) => {
      const question = questions[index];
      const answer = answers[`question${index}`];
      
      if (question.toLowerCase().includes("upload")) {
        // Get the original File object from the ref
        const fileData = fileRefs.current[`question${index}`];
        if (fileData) {
          formData.append(`file${arrayIndex + 1}`, fileData, fileData.name);
        }
      }
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/question/${userId}`, {
        method: "POST",
        body: formData,
      });
      if (!response.ok) throw new Error("Failed to send data");
      console.log("Data sent successfully");

      const newAnswers = { ...answers };
      delete newAnswers[`question${questionIndex1}`];
      delete newAnswers[`question${questionIndex2}`];
      setAnswers(newAnswers);
      saveFormState();
      
      if (questionIndex2 === questions.length - 1) {
        
        setTimeout(() => {
          navigate('/dashboard');
        }, 1500);
      }
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  // Add a ref to store the actual File objects
  const fileRefs = useRef({});

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Store the serialized file info in the answers state
      const serializedFile = serializeFile(file);
      const newAnswers = { ...answers, [`question${currentQuestionIndex}`]: serializedFile };
      setAnswers(newAnswers);
      
      // Store the actual File object in the ref
      fileRefs.current[`question${currentQuestionIndex}`] = file;
      
      localStorage.setItem('formState', JSON.stringify({
        answers: newAnswers,
        currentQuestionIndex,
        messages
      }));
    }
  };

  const onSubmit = async () => {
    if (!validateCurrentAnswer()) return;

    const currentAnswer = answers[`question${currentQuestionIndex}`];
    const newMessages = [...messages];
    newMessages.push(
      questions[currentQuestionIndex].toLowerCase().includes("upload")
        ? { type: 'file', file: currentAnswer, sender: "user" }
        : { text: currentAnswer, sender: "user" }
    );

    if (currentQuestionIndex < questions.length - 1) {
      setIsTyping(true);
      setTimeout(() => {
        newMessages.push({ text: questions[currentQuestionIndex + 1], sender: "Claude" });
        setMessages(newMessages);
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setIsTyping(false);
        
        localStorage.setItem('formState', JSON.stringify({
          answers,
          currentQuestionIndex: currentQuestionIndex + 1,
          messages: newMessages
        }));
      }, 1000);
    } else {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/mark/${userId}`, {
        method: "POST",
      });

      const data = await response.json()
      console.log({data})
      newMessages.push({ text: "Your application has been successfully submitted.", sender: "Claude" });
      setMessages(newMessages);
    }

    setMessages(newMessages);

    const isEndOfBlock = (currentQuestionIndex + 1) % 2 === 0;
    if (isEndOfBlock) {
      sendToBackend(currentQuestionIndex - 1, currentQuestionIndex);
    }
  };

  return (
    <div className="flex justify-between  w-[100%] bg-[#2a2a2a] text-white">
       {loading && <Spinner />}
      <motion.div 
        className={` hidden bg-[#2a2a2a] text-white h-screen w-16 p-4 lg:flex flex-col justify-between transition-all duration-300 ${isPaneOpen ? 'w-64 rounded-lg mt-1 mx-3' : 'w-16'}  ${isHovered ? 'bg-[#444444]' : '#2a2a2a'}`}
        initial={false}
        animate={{ width: isPaneOpen ? "18rem" : "17rem" }}
        onMouseEnter={() => {
          setPaneOpen(true);
          setHovered(true);
        }} 
        onMouseLeave={() => {
          setPaneOpen(false);
          setHovered(false);
        }}
      >
        {/* Top Section */}
        <div className="space-y-4">
          {/* Logo or Header */}
          <div className="flex justify-start">
          <Link to='/dashboard'>
            <img  src="/LogoForMG.png" alt="Logo" className="w-10 h-10 rounded-full" />
            </Link>
          </div>

          {/* Start new chat */}
          {isPaneOpen && (
            <button className="bg-black w-full text-left p-3 flex items-center justify-between rounded-md hover:bg-gray-800" onClick={newPage}>
              <span className="font-semibold">Start new chat</span>
              <FaPlus />
            </button>
          )}

        
          {/* Recent Chats */}
          {isPaneOpen && (
            <div className="mt-6">
              <h2 className="text-gray-400 mb-2 text-sm font-bold">Recents</h2>
              {recentChats.length === 0 ? (
          <div className="col-span-1 md:col-span-2 lg:col-span-3 text-center text-gray-500">
            No recent chats
          </div>
        ) : (
          // Chat Items
          recentChats.map((chat) => (
            <motion.div
              key={chat._id}
              whileHover={{ scale: 1.05 }}
              className="bg-gray-800 dark:bg-[#383838] bg-opacity-90 p-4 rounded-lg shadow-md flex justify-between items-center transition-transform duration-300 mt-3"
            >
              <div>
                <p className="text-sm text-gray-400">{chat.chatName}</p>
              
              </div>
              <button
                className="text-blue-500 dark:text-blue-300 text-sm hover:underline hover:text-blue-600 dark:hover:text-blue-400"
                onClick={() => {
                  // Handle view chat action
                  navigate('/chat', { state: {  chatId : chat._id } });
                }}
              >
                View
              </button>
            </motion.div>
          ))
        )}
            </div>
          )}
        </div>

        {/* Bottom Section - Profile */}
        <div className="flex items-center justify-start p-4">
          <div className="bg-gray-700 p-2 w-[40px] h-[40px] text-center rounded-full">
            <span className="text-white text-sm font-bold">{name.charAt(0)}</span>
          </div>
          {isPaneOpen && (
            <div className="ml-2">
              <div className="font-bold">{name}</div>
              {info ? (
      
      <div className="text-gray-400 text-sm">{info.subscriptionPlan}</div>
   ) : (
     "Loading user info..."
   )}
            </div>
          )}
        </div>
      </motion.div>

      <div className="w-full lg:w-3/6 h-screen flex flex-col overflow-auto justify-between p-6 mt-3 rounded">
      <div className="mb-32" >
        {messages.map((message, index) => (
          <div key={index} className={`message mb-4 ${message.sender === "user" ? "text-right" : "text-left"}`}>
            {message.type === 'file' ? (
              <FilePreviewMessage file={message.file} />
            ) : (
              <p className={`message-bubble inline-block p-2 text-white rounded-lg max-w-[80%] ${
                message.sender === "user" ? "bg-blue-600" : "bg-gray-700"
              }`}>
                {message.text}
              </p>
            )}
          </div>
        ))}
          {isTyping && (
             <div className="flex items-center justify-start mb-4">
             <div className="flex space-x-1">
               <span className="w-2 h-2 bg-gray-500 rounded-full animate-typing"></span>
               <span className="w-2 h-2 bg-gray-500 rounded-full animate-typing delay-200"></span>
               <span className="w-2 h-2 bg-gray-500 rounded-full animate-typing delay-400"></span>
             </div>
           </div>
          )}
        <div ref={chatEndRef} />
      </div>
      <div className="w-full max-w-[450px]  md:max-w-[600px] flex items-center bg-gray-700 rounded-lg fixed bottom-2 px-4">
        {questions[currentQuestionIndex].toLowerCase().includes("upload") ? (
          <label htmlFor="file-upload" className="w-full cursor-pointer p-3 text-center bg-gray-600 rounded-lg">
            {answers[`question${currentQuestionIndex}`]?.name || "Upload file"}
          </label>
        ) : (
          <input
            type="text"
            value={answers[`question${currentQuestionIndex}`] || ""}
            onChange={handleTextInput}
            className=" w-full flex-1 p-3 bg-gray-700 rounded-lg focus:outline-none "
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSubmit(e);  
              }
            }}
            placeholder="Type your answer..."
          />
        )}
          <button
            onClick={onSubmit}
            disabled={loading || isTyping}
            className={`ml-2 text-blue-400 hover:text-blue-600 px-4`}
          >
            <FaPaperPlane className="inline-block mr-2" />
       
          </button>
        <input type="file" id="file-upload" className="hidden" onChange={handleFileUpload} />
      </div>
      
      </div>
     

      <div className="hidden lg:block w-1/6 p-4 bg-[#2a2a2a] mt-3">
        <div>
          <p className="mb-4 font-bold">Other Activities</p>
          <p className="text-gray-400 text-[13px]">MigrantGenie AI</p>
          <p className="text-gray-400 text-[13px]">Immigration AI</p>
        </div>

       

        {
          info?.subscribed && (
            <div>
            <p className="mt-4 text-[10px]">Options</p>
            {options.map((option, index) => (
              <div key={index}
                className="flex rounded-lg p-1 mt-2 bg-[#333333] shadow-lg cursor-pointer"
                onClick={option.onClick} // Attach the onClick handler
              >
                <div className="flex justify-center items-center bg-[#1f1f1f] px-4 py-2">
                  {option.icon}
                </div>
                <div className="flex items-center">
                  <p className="text-[12px] pl-2 border-l">{option.text}</p>
                </div>
              </div>
            ))}
          </div>
          )
        }
      </div>
    </div>
  );
};

export default Dashboard;