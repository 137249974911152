import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const PaymentForm = ({ amount, onClose , plan, userId, location}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const handlePayment = async () => {
    setIsProcessing(true);

    try {
      // Create Payment Intent on the backend
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/pay`, { amount });
      console.log({response})
      const { clientSecret } = response.data;

      // Confirm payment with Stripe.js
      const cardElement = elements.getElement(CardElement);
      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: 'Your Customer Name',
          },
        },
      });

      if (error) {
        console.error('Payment failed:', error);
        toast.error(`${error.message}`);
      } else {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/subscribe/${userId}`, { plan });
        console.log({response})
        
        if(!response.ok){
          toast.error('Payment successful!');
          
        }
        toast.success('Payment successful!');
        navigate(`/${location}`)
        localStorage.setItem('location', location);
        // onClose(); 
      }
    } catch (error) {
      console.error('Error creating payment intent:', error.message.message);
      toast.error(`${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        color: '#ffffff',  // White text
        fontSize: '16px',
        '::placeholder': {
          color: '#a0aec0',  // Light gray placeholder color
        },
      },
      invalid: {
        color: '#fa755a', // Red text for invalid card details
      },
    },
  };
  return (
    <div className="mt-6 text-white">
          <ToastContainer />
      <h4 className="font-bold mb-2">Payment Details</h4>
      <CardElement className="border p-4 rounded " options={cardElementOptions} />
      <button 
        className="bg-blue-500 text-white py-2 px-4 rounded-lg mt-4" 
        onClick={handlePayment}
        disabled={isProcessing}
      >
        Pay ${amount / 100} {/* Display amount in dollars */}
      </button>
    </div>
  );
};

export default PaymentForm;
