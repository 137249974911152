import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check for the token in session storage
    const token = sessionStorage.getItem('token'); // Replace with your actual token key

    if (!token) {
      // If no token is found, redirect to the login page
      navigate('/login'); // Assuming your login route is /login
    }
  }, [navigate]);

  return children; // Render the children (protected content) if the token exists
};

export default ProtectedRoute;
